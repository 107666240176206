import cuid from 'cuid';
import { forwardRef, useMemo } from 'react';
import type {
  ClearIndicatorProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
} from 'react-select';
import { defaultTheme } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { useTheme } from 'styled-components';
import { LabelWithRequired } from 'design-system-extended';
import { LoadingSpinner, Text } from '@beamery/lib-ds-components';
import { Close } from '@beamery/lib-ds-icons';
import { useTranslation } from '../../../../hooks/use-translation';
import type {
  TypeaheadOption,
  TypeaheadProps,
} from './core-typeahead.interface';
import {
  Container,
  StyledPill,
  ClearButton,
  RemoveButton,
  LoadingContainer,
} from './core-typeahead.styles';

const MultiValueContainer = ({
  children,
}: MultiValueGenericProps<TypeaheadOption>) => (
  <StyledPill>{children}</StyledPill>
);

const MultiValueRemove = ({
  innerProps: { onClick },
}: MultiValueRemoveProps<TypeaheadOption>) => (
  <RemoveButton onClick={onClick as () => void} type='button'>
    <Close size='small' />
  </RemoveButton>
);

const MultiValueLabel = ({
  children,
}: MultiValueGenericProps<TypeaheadOption>) => <span>{children}</span>;

const LoadingMessage = () => (
  <LoadingContainer>
    <LoadingSpinner />
  </LoadingContainer>
);

const ClearIndicator = ({
  clearValue,
}: ClearIndicatorProps<TypeaheadOption>) => (
  <ClearButton tabIndex={-1} onClick={clearValue} type='button'>
    <Close />
  </ClearButton>
);

const EmptyComponent = () => null;

const mapValue = (
  value: ReadonlyArray<TypeaheadOption> | TypeaheadOption | null,
) => {
  if (value instanceof Array) {
    return value;
  }
  if (value) {
    return [value];
  }

  return [];
};

const CoreTypeaheadComponent = (
  {
    onChange,
    onBlur,
    isRequired = false,
    value,
    label,
    name,
    isMulti = false,
    errorMessage,
    loadOptions,
    allowCreate = false,
    searchMinimumCharacters = 1,
    noResultsMessage,
  }: TypeaheadProps,
  ref?: React.ForwardedRef<HTMLInputElement> | undefined,
) => {
  const { t } = useTranslation();

  const id = useMemo(() => cuid(), []);
  const theme = useTheme();

  const Component = allowCreate ? AsyncCreatableSelect : AsyncSelect;

  return (
    <Container>
      <LabelWithRequired isRequired={isRequired} htmlFor={id}>
        {label}
      </LabelWithRequired>
      <Component
        inputId={id}
        isMulti={isMulti}
        isClearable
        components={{
          MultiValueContainer,
          MultiValueRemove,
          MultiValueLabel,
          DropdownIndicator: EmptyComponent,
          IndicatorSeparator: EmptyComponent,
          ClearIndicator,
          LoadingMessage,
        }}
        formatCreateLabel={(text) => `"${text}"`}
        styles={{
          control: (base) => ({
            ...base,
            fontSize: theme.fontSizes.s,
            minHeight: theme.styles.inputs.base.minHeight,
            background: theme.colors.controls.secondary,
            outlineOffset: theme.outlines.input.offset,
            border: 'none',
            outline: errorMessage
              ? `${theme.borderWidths.sm} ${theme.borderStyles.solid} ${theme.colors.borders.error}`
              : 'none',
            cursor: 'text',
            ':hover': {
              ...base[':hover'],
              background: theme.colors.controls.secondaryHover,
            },
            ':focus-within': {
              ...base[':focus-within'],
              outline: `${theme.outlines.input.rule} !important`,
              boxShadow: 'unset',
              background: theme.colors.backgrounds.none,
            },
          }),
          valueContainer: (base) => ({
            ...base,
            rowGap: theme.space.x1,
          }),
          menu: (base) => ({
            ...base,
            fontSize: theme.fontSizes.s,
            borderRadius: 0,
            boxShadow: '0px 8px 16px rgb(31 31 35 / 12%)',
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
          }),
          option: (base, { isFocused }) => ({
            ...base,
            background: 'unset',
            outline: isFocused
              ? `2px solid ${theme.colors.outlines.default}`
              : 'none',
            outlineOffset: '-2px',
            cursor: 'pointer',
            ':active': {
              ...base[':active'],
              background: theme.colors.backgrounds.default,
            },
          }),
        }}
        theme={{
          ...defaultTheme,
          borderRadius: 6,
        }}
        value={value}
        openMenuOnFocus={false}
        openMenuOnClick={false}
        placeholder=''
        noOptionsMessage={({ inputValue }) =>
          inputValue.length < searchMinimumCharacters
            ? ''
            : noResultsMessage || t('typeahead.no-results')
        }
        onChange={(newValue) => onChange?.(mapValue(newValue))}
        loadOptions={loadOptions}
        name={name}
        onBlur={onBlur}
        ref={(x) => {
          if (ref) {
            if (typeof ref === 'function') {
              ref(x?.inputRef ?? null);
            } else {
              // eslint-disable-next-line no-param-reassign
              ref.current = x?.inputRef ?? null;
            }
          }
        }}
      />
      {errorMessage && (
        <div role='alert'>
          <Text color='error' fontSize='s'>
            {errorMessage}
          </Text>
        </div>
      )}
    </Container>
  );
};

export const CoreTypeahead = forwardRef(CoreTypeaheadComponent);
