import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Toast } from 'design-system-extended';
import { useState } from 'react';
import { CandidateForm } from '../components/candidate-form';
import { PageNotFound } from '../components/page-not-found';

export const CandidateFormPageComponent = () => {
  const { companySlug, formSlug = '' } = useParams();
  const [page, setPage] = useState(0);

  if (formSlug === undefined || page === undefined) {
    return <PageNotFound />;
  }

  return (
    <>
      <CandidateForm
        companySlug={companySlug}
        formSlug={formSlug}
        page={page}
        onPageChange={setPage}
        urlParameterString={window.location.search}
      />
      <Toast />
    </>
  );
};

export const CandidateFormPage = () => (
  <Routes>
    <Route index element={<CandidateFormPageComponent />} />
    <Route path='/:page' element={<Navigate to='..' replace />} />
    <Route path='*' element={<PageNotFound />} />
  </Routes>
);
