/* eslint-disable max-classes-per-file */
import type { DocumentNode, GraphQLError } from 'graphql';

export { GraphQLError } from 'graphql';

export class GqlError extends Error {
  name: string;

  message: string;

  graphQLErrors: GraphQLError[];

  constructor(errors: GraphQLError[]) {
    const message = errors.map((err) => err.message).join(', ');
    super(message);
    this.name = 'GqlError';
    this.message = message;
    this.graphQLErrors = errors;
  }
}

export class ServerError extends Error {
  name: string;

  message: string;

  status: number;

  constructor(response: Response) {
    const message = response.statusText;
    super(message);
    this.name = 'ServerError';
    this.message = response.statusText;
    this.status = response.status;
  }
}

export type FetchError = ServerError | GqlError | Error;

export interface FetchGqlResult<T> {
  errors?: GraphQLError[];
  data?: T;
}

interface FetchOptionsBase<TVariables> {
  variables?: TVariables;
  headers?: Record<string, string>;
}

interface FetchGqlQuery<TVariables> extends FetchOptionsBase<TVariables> {
  query: DocumentNode;
}

interface FetchGqlMutation<TVariables> extends FetchOptionsBase<TVariables> {
  mutation: DocumentNode;
}

export type FetchGqlOptions<TVariables> =
  | FetchGqlQuery<TVariables>
  | FetchGqlMutation<TVariables>;

interface CreateFetchGqlClientOptions {
  url: string;
  onError?: (e: FetchError) => void;
  headers?: Record<string, string>;
}

export const createFetchGqlClient =
  ({
    url,
    headers: clientHeaders = {},
    onError,
  }: CreateFetchGqlClientOptions) =>
  async <T = unknown, TVariables = Record<string, unknown>>(
    options: FetchGqlOptions<TVariables>,
  ) => {
    try {
      const query = 'query' in options ? options.query : options.mutation;
      const requestHeaders = options.headers ?? {};
      const response = await fetch(url, {
        headers: {
          ...clientHeaders,
          ...requestHeaders,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          query: query.loc?.source.body,
          variables: options.variables ?? {},
        }),
      });

      if (!response.ok) {
        throw new ServerError(response);
      }

      const { data, errors } = (await response.json()) as FetchGqlResult<T>;

      if (errors && errors.length > 0) {
        throw new GqlError(errors);
      }

      if (!data) {
        throw new Error('Unknown error');
      }

      return data as T;
    } catch (e) {
      if (e instanceof Error) {
        onError?.(e);
      }
      throw e;
    }
  };
