import { useEffect, useMemo } from 'react';
import { useCurrentDomain } from 'candidate-facing-components';
import { getCaptchaPublicKey, injectRecaptcha } from './utils';

export const useRecaptcha = (enabled: boolean) => {
  const { parentDomain } = useCurrentDomain();

  const key = useMemo(() => getCaptchaPublicKey(parentDomain), [parentDomain]);

  useEffect(() => {
    if (key && enabled) {
      injectRecaptcha(key);
    }
  }, [key, enabled]);

  return enabled ? key : null;
};
