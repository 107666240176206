import { useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import { isEmbedded } from '../../env';
import type { CustomFontProviderProps } from './custom-font-provider.interface';

const mergeFonts = ({
  $fontFamilyName,
  theme,
}: {
  $fontFamilyName: string;
  theme: DefaultTheme;
}) => `'${$fontFamilyName}', ${theme.fonts.body}`;

const Wrapper = styled.div<{ $fontFamilyName: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & * {
    font-family: ${mergeFonts};
  }
`;

/* Workaround for design system popover rendered in a portal */
const GlobalStyles = createGlobalStyle<{ $fontFamilyName: string }>`
  [data-automation="convert-form-select/list-box"] * {
    font-family: ${mergeFonts};
  }
`;

export const CustomFontProvider = ({
  fontUrl,
  fontFamilyName,
  children,
}: CustomFontProviderProps) => {
  const initialised = useRef(false);

  useEffect(() => {
    if (initialised.current || !fontUrl || !fontFamilyName || isEmbedded) {
      return;
    }

    initialised.current = true;

    const hasLink = Array.from(document.getElementsByTagName('link')).some(
      (link) =>
        link.href === fontUrl && link.getAttribute('rel') === 'stylesheet',
    );

    if (hasLink) {
      return;
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = fontUrl;

    document.head.appendChild(link);
  }, [fontFamilyName, fontUrl]);

  if (!fontUrl || !fontFamilyName || isEmbedded) {
    return <>{children}</>;
  }

  return (
    <>
      <GlobalStyles $fontFamilyName={fontFamilyName} />
      <Wrapper $fontFamilyName={fontFamilyName}>{children}</Wrapper>
    </>
  );
};
