import { forwardRef } from 'react';
import type { Options } from 'react-select';
import { delay } from 'utils';
import type { LocationProviderResult } from 'form-definition';
import { frontierServiceUrl } from '../../../utils/url';
import { CoreTypeahead } from './core-typeahead';
import type { TypeaheadProps } from './core-typeahead/core-typeahead.interface';

type LocationPickerProps = Omit<
  TypeaheadProps,
  'allowCreate' | 'loadOptions' | 'searchMinimumCharacters'
>;

export interface LocationV1Result {
  id: string;
  label: string;
}

export interface LocationV1Response {
  locations: Array<LocationV1Result>;
}

const maxAttempts = 2;

const getLocations = async (
  search: string,
  attempt = 1,
): Promise<Options<{ value: string; label: string }>> => {
  const urlencoded = encodeURIComponent(search);
  try {
    const result = await fetch(
      `${frontierServiceUrl}/location/v2/location?q=${urlencoded}`,
    );
    const locations = (await result.json()) as LocationProviderResult[];

    return locations.map((location) => ({
      value: JSON.stringify(location),
      label: location.formattedAddress,
    }));
  } catch (e) {
    if (attempt < maxAttempts) {
      return getLocations(search, attempt + 1);
    }
    throw e;
  }
};

const getLocationsDebounced = (() => {
  let latest = 0;
  return async (...args: Parameters<typeof getLocations>) => {
    latest += 1;
    const instance = latest;

    await delay(300);

    // If this is not the latest request, ignore it.
    if (latest !== instance) {
      return [];
    }
    return getLocations(...args);
  };
})();

const LocationPickerComponent = (
  props: LocationPickerProps,
  ref?: React.ForwardedRef<HTMLInputElement> | undefined,
) => (
  <CoreTypeahead
    {...props}
    searchMinimumCharacters={3}
    ref={ref}
    loadOptions={(inputValue) =>
      inputValue.length < 3
        ? Promise.resolve([])
        : getLocationsDebounced(inputValue)
    }
  />
);

export const LocationPicker = forwardRef(LocationPickerComponent);
