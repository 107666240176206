/* eslint-disable import/no-extraneous-dependencies */
import type { DesignSystemProviderProps } from '@beamery/lib-ds-components';
import {
  GlobalStyle,
  CopyProvider,
  OverlayProvider,
} from '@beamery/lib-ds-components';
import {
  ThemeProvider,
  StyleSheetManager,
  createGlobalStyle,
} from 'styled-components';
import { isEmbedded } from '../../env';

const generateRandomClassNamesPlugin = () => {
  /* Do nothing */
};
Object.defineProperty(generateRandomClassNamesPlugin, 'name', {
  value: 'app-convert',
});

/* Workaround for design system popover rendered in a portal */
const GlobalStyleOverides = createGlobalStyle`
  [data-automation="convert-form-select/list-box"] * {
    box-sizing: border-box;
  }
`;

export const DesignSystemProvider = ({
  children,
  copy,
  theme,
}: Omit<DesignSystemProviderProps, 'appId'>) => (
  <OverlayProvider>
    <StyleSheetManager stylisPlugins={[generateRandomClassNamesPlugin]}>
      <ThemeProvider
        theme={{
          ...theme,
          fonts: {
            ...theme.fonts,
            body: isEmbedded ? 'inherit' : theme.fonts.body,
          },
        }}
      >
        {isEmbedded ? <GlobalStyleOverides /> : <GlobalStyle />}
        <CopyProvider copy={copy}>{children}</CopyProvider>
      </ThemeProvider>
    </StyleSheetManager>
  </OverlayProvider>
);
