import { getCurrentScriptBaseDomain } from './scripts';

const domains: Record<string, string> = {};

export const replaceService = (hostname: string, service: string) =>
  hostname.replace(/^[^.]*/, service);

export const getServiceDomain = (
  service: string,
  stagingEnvironment: string,
  isDev: boolean,
) => {
  if (isDev) {
    return `${service}.${stagingEnvironment}.staging.beamery.engineer`;
  }

  if (!domains[service]) {
    domains[service] = replaceService(window.location.hostname, service);
  }

  return domains[service];
};

export const getServiceUrl = (
  service: string,
  stagingEnvironment: string,
  isDev: boolean,
) => `https://${getServiceDomain(service, stagingEnvironment, isDev)}`;

export const getEmbeddedServiceUrl = (
  service: string,
  stagingEnvironment: string,
  isDev: boolean,
) => {
  if (isDev) {
    return getServiceUrl(service, stagingEnvironment, isDev);
  }

  return `https://${replaceService(getCurrentScriptBaseDomain(), service)}`;
};

export const isVanityDomain = (isEmbedded: boolean) => {
  if (process.env.FAKE_VANITY) {
    return true;
  }

  const hostnameParts = window.location.hostname.split('.');

  return (
    window.location.hostname !== 'localhost' &&
    !isEmbedded &&
    (hostnameParts[hostnameParts.length - 2] !== 'beamery' ||
      window.location.hostname.endsWith('beamery.tech') ||
      window.location.hostname.endsWith('vanitytest.beamery.local'))
  );
};

export const getFrontierServiceUrl = (
  stagingEnvironment: string,
  isDev: boolean,
  isEmbedded: boolean,
) => {
  if (isEmbedded) {
    return getEmbeddedServiceUrl('frontier', stagingEnvironment, isDev);
  }
  if (isVanityDomain(isEmbedded)) {
    return `https://${window.location.host}`;
  }
  return getServiceUrl('frontier', stagingEnvironment, isDev);
};
