import styled from 'styled-components';
import { Paragraph } from '@beamery/lib-ds-components';

export const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${({ theme }) => theme.space.x3};
`;

export const OrderedList = styled.ol`
  font-size: ${({ theme }) => theme.fontSizes.s};
  padding-inline-start: ${({ theme }) => theme.space.x6};
  line-height: 1.5;
  list-style-type: decimal;

  ${StyledParagraph} {
    margin-bottom: 0;
  }

  & > ol,
  & > li > ol {
    list-style-type: lower-latin;

    & > ol,
    & > li > ol {
      list-style-type: lower-roman;
    }
  }
`;

export const UnorderedList = styled.ul`
  font-size: ${({ theme }) => theme.fontSizes.s};
  padding-inline-start: ${({ theme }) => theme.space.x6};
  line-height: 1.5;

  ${StyledParagraph} {
    margin-bottom: 0;
  }
`;
