import { useEffect } from 'react';
import { EmptyState } from '@beamery/lib-ds-components';
import { useTranslation } from 'react-i18next';

export const PageNotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('candidate-experience.not-found');
  }, [t]);

  return (
    <EmptyState
      heading={t('candidate-experience.page-not-found.heading')}
      content={t('candidate-experience.page-not-found.body')}
    />
  );
};
