import type { ReactNode } from 'react';
import { useContext, createContext, useMemo } from 'react';
import { noop } from '../general';

export interface Logger {
  error: (error: unknown, meta?: unknown) => void;
  info: (message: string, meta?: unknown) => void;
}

const defaultLogger: Logger = {
  error: noop,
  info: noop,
};

const LoggerContext = createContext<Logger>(defaultLogger);

interface LoggerProviderProps {
  logError: Logger['error'];
  logInfo: Logger['info'];
  children: ReactNode;
}

export const LoggerProvider = ({
  logError,
  logInfo,
  children,
}: LoggerProviderProps) => {
  const value = useMemo(
    () => ({ error: logError, info: logInfo }),
    [logError, logInfo],
  );

  return (
    <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>
  );
};

export const useLogger = () => useContext(LoggerContext);
