import type { ElementValue } from 'form-definition';
import type { SubmissionAnswer } from './form.interface';

export const ensureString = (value: ElementValue, defaultValue = '') =>
  typeof value === 'string' ? value : defaultValue;

export const ensureFileName = (value: ElementValue, defaultValue = '') =>
  Array.isArray(value) ? ensureString(value[0]) : defaultValue;

export const ensureArray = (
  value: ElementValue,
  defaultValue: string[] = [],
) => (Array.isArray(value) ? value : defaultValue);

const mapTypeaheadOptionString = (
  value: string,
  labelProperty: string | null = null,
): { value: string; label: string } => {
  if (labelProperty) {
    try {
      const parsedJson = JSON.parse(value) as unknown;
      if (parsedJson && typeof parsedJson && !Array.isArray(parsedJson)) {
        const label = (parsedJson as Record<string, unknown>)[labelProperty];
        if (typeof label === 'string' && label.length > 0) {
          return { value, label };
        }
      }
    } catch {
      // Do nothing, so default is returned
    }
  }
  return { value, label: value };
};

export const ensureTypeaheadOption = (
  value: ElementValue,
  labelProperty: string | null = null,
  defaultValue = '',
) => {
  if (Array.isArray(value)) {
    return JSON.stringify(
      value.map((option) => mapTypeaheadOptionString(option, labelProperty)),
    );
  }
  if (typeof value === 'string' && value.length > 0) {
    return JSON.stringify([mapTypeaheadOptionString(value, labelProperty)]);
  }
  return defaultValue;
};

export const ensureStringOrArrayToString = (value: ElementValue) => {
  if (Array.isArray(value)) {
    return value.join('; ');
  }
  if (typeof value === 'string') {
    return value;
  }
  return '';
};

export const ensureFileValue = (value: SubmissionAnswer) => {
  if (value instanceof File) {
    return value;
  }
  // fake file object for the file input
  if (typeof value === 'string' && value.length > 0) {
    return new File([value], value);
  }
  return null;
};

export const hasValue = (value: ElementValue) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return typeof value === 'string' && value.length > 0;
};

const matchCdnUrl = /^https:\/\/cdn\.fs\.beamery\.com\/(api\/file\/)?(.+)/;

interface ImageSrcOptions {
  url: string;
  width?: number;
  height?: number;
  upscale?: boolean;
}

interface ResizeImage extends ImageSrcOptions {
  multiplier?: number;
}

const resizeImage = ({
  url,
  multiplier = 1,
  width,
  height,
  upscale = false,
}: ResizeImage) => {
  const w = width ? `width:${width * multiplier},` : '';
  const h = height ? `height:${height * multiplier},` : '';
  const fit = upscale ? 'clip' : 'max';
  const resize = width || height ? `resize=${w}${h}fit:${fit}` : '';
  const [_url, _optionalPath, fileHandle] = url.match(matchCdnUrl) ?? [];
  return resize && fileHandle
    ? `https://cdn.fs.beamery.com/${resize}/fallback=file:${fileHandle},cache:86400/${fileHandle}`
    : url;
};

const generateSrcSet = (imgSrc: ImageSrcOptions) =>
  imgSrc.width || imgSrc.height
    ? `${resizeImage(imgSrc)}, ${resizeImage({ ...imgSrc, multiplier: 2 })} 2x, ${resizeImage(
        {
          ...imgSrc,
          multiplier: 3,
        },
      )} 3x`
    : undefined;

export const generateImageSrcs = ({
  url,
  ...rest
}: Partial<ImageSrcOptions>) => {
  const isCdnUrl = url && matchCdnUrl.test(url);
  if (!isCdnUrl) {
    return {
      src: url,
    };
  }

  return {
    src: resizeImage({ url, ...rest }),
    srcSet: generateSrcSet({ url, ...rest }),
  };
};
