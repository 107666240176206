import type { CookieConsent } from 'form-definition';
import { fallbackConfig } from '../../config/onetrust.config';
import { injectScript } from '../../utils';

const getOneTrustConfig = (
  { apiKeys, cdn }: CookieConsent,
  isEventsUrl: boolean,
) => ({
  apiKey: isEventsUrl && apiKeys.events ? apiKeys.events : apiKeys.flows,
  cdn: cdn || fallbackConfig.cdn,
});

let initialised = false;

interface InjectOneTrustOptions {
  settings: CookieConsent;
  isEventsUrl: boolean;
  callback: (group: string) => void;
  isVanityDomain: boolean;
}

export const injectOneTrust = ({
  settings,
  isEventsUrl,
  callback,
  isVanityDomain,
}: InjectOneTrustOptions) => {
  if (initialised) {
    return;
  }

  initialised = true;

  const { test } = settings;

  const { apiKey, cdn } = getOneTrustConfig(settings, isEventsUrl);

  // Prepend different file path if we are on a vanity domain - see docs/vanity-urls.md#static-assets
  const emptyFilePath = isVanityDomain ? '/flows/assets/empty.js' : '/empty.js';

  window.OptanonWrapper = () => {
    OneTrust.InsertScript(
      emptyFilePath,
      'head',
      () => callback('C0002'),
      null,
      'C0002',
      false,
    );
    OneTrust.InsertScript(
      emptyFilePath,
      'head',
      () => callback('C0004'),
      null,
      'C0004',
      false,
    );
  };

  injectScript(`https://${cdn}/scripttemplates/otSDKStub.js`, {
    charset: 'UTF-8',
    'data-domain-script': `${apiKey}${test ? '-test' : ''}`,
  });
  injectScript(`https://${cdn}/consent/${apiKey}/OtAutoBlock.js`);
};
