import { Flex, Form } from '@beamery/lib-ds-components';
import styled, { css } from 'styled-components';

export const maxFormWidth = '800px';

const styles = css`
  width: 100%;
  max-width: ${maxFormWidth};
  gap: ${({ theme }) => theme.space.x4};
`;

export const HtmlForm = styled(Form)`
  ${styles}
  position: relative;
`;

export const NonFormContainer = styled(Flex)`
  ${styles}
`;
NonFormContainer.defaultProps = {
  direction: 'column',
};
