import { getAuthenticationUrl } from '../utils/candidate-auth';

export class AuthError extends Error {
  name: string;

  message: string;

  constructor(message: string) {
    super(message);
    this.name = 'AuthError';
    this.message = message;
  }
}

export const refreshAuthToken = async (slug: string) => {
  try {
    const response = await fetch(getAuthenticationUrl(slug), {
      credentials: 'include',
    });
    if (response.status !== 200) {
      throw new AuthError('Error freshing auth token');
    }
  } catch {
    throw new AuthError('Error freshing auth token');
  }
};
