import { candidateAuth } from '../api/endpoints';

export const getAuthenticationUrl = (slug: string) =>
  `https://${window.location.host}/${candidateAuth}?redirectUrl=/flows/${slug}`;

export const getPortalUrl = ({
  email,
  customDomain,
}: {
  email: string;
  customDomain?: string;
}) =>
  `https://${
    customDomain ?? window.location.host
  }/${candidateAuth}/send-otp?email=${email}`;

export const getSignOutUrl = () =>
  `https://${window.location.host}/${candidateAuth}/logout`;
