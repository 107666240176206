import { googleAnalyticsId } from '../config/google-analytics.config';

/**
 * Sets a temporary function that data can be pushed to while Google Analytics is loading.
 * GA will then push this data when it loads.
 */
export const createTemporaryGoogleAnalyticsFn = () => {
  if (!window.gtag) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer?.push(arguments);
    };
  }
};

const createTracker = (trackingId: string) => {
  if (!window.gtag) {
    createTemporaryGoogleAnalyticsFn();
  }
  window.gtag?.('config', trackingId);
};

const createClientTracker = (id: string) => {
  createTracker(id);
};

const createBeameryTracker = () => {
  createTracker(googleAnalyticsId);
};

let initialised = false;

export const injectGAScript = (clientGaKey?: string | null) => {
  // We only initialise GA for Beamery if we are also initialising it for a client - see TP-873 for more details
  if (initialised || !clientGaKey) return;

  createTemporaryGoogleAnalyticsFn();

  window.gtag?.('js', new Date());
  createBeameryTracker();
  createClientTracker(clientGaKey);

  const gaScript = document.createElement('script');
  gaScript.type = 'text/javascript';
  gaScript.async = true;
  gaScript.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`,
  );

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(gaScript);

  initialised = true;
};
