import {
  NavigationProvider,
  Sidebar,
  TopNavBar,
} from '@beamery/canex-navigation';
import { useTranslation } from 'react-i18next';
import { getSignOutUrl } from '../../utils/candidate-auth';
import type { CandidateExperienceNavProps } from './candidate-experience-nav.interface';
import { Wrapper, Content } from './candidate-experience-nav.styles';

export const CandidateExperienceNav = ({
  isLoggedIn,
  children,
  logoUrl,
  companyName,
  navItems = [],
}: CandidateExperienceNavProps) => {
  const { t } = useTranslation();

  if (!isLoggedIn) {
    return <>{children}</>;
  }

  return (
    <NavigationProvider
      logo={logoUrl}
      companyName={companyName}
      items={[
        navItems,
        [
          {
            title: t('candidate-experience.canex-navigation.sign-out'),
            id: 'sign-out',
            icon: 'SignOut',
            href: getSignOutUrl(),
          },
        ],
      ]}
    >
      <TopNavBar
        aria-label={t('candidate-experience.canex-navigation.top-nav')}
      />
      <Wrapper>
        <Sidebar
          aria-label={t('candidate-experience.canex-navigation.side-nav')}
        />
        <Content>{children}</Content>
      </Wrapper>
    </NavigationProvider>
  );
};
