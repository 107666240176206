export interface FeatureFlag {
  value: boolean;
  localStorageName: string;
}

export const getOverridableFeatureFlagValue = ({
  value: defaultValue,
  localStorageName,
}: FeatureFlag): boolean => {
  try {
    const localValue = localStorage.getItem(localStorageName);
    if (localValue) {
      switch (localValue.toLowerCase()) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return defaultValue;
      }
    }
    return defaultValue;
  } catch {
    return defaultValue;
  }
};

export const launchDarklyFeatureFlag = <T = boolean>(flagName: string) =>
  // @ts-expect-error global object only present in app-bath
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  window.$$BMR_FEATURE_FLAGS$$?.clients?.['beamery-saas']?.variation(
    flagName,
  ) as T;
