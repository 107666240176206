import {
  Flex,
  Heading,
  Link,
  Paragraph,
  Stack,
} from '@beamery/lib-ds-components';
import { SanitizedHtml } from 'design-system-extended';
import styled from 'styled-components';
import { useTranslation } from '../../../../hooks/use-translation';
import { dataEnrichmentUrl } from '../../../../config/beamery.config';
import type { ComplianceProps } from './compliance.interface';
import { DataProtectionField } from './data-protection-field';

const Container = styled(Flex)`
  gap: ${({ theme }) => theme.space.x1};
  position: relative;
`;
Container.defaultProps = {
  direction: 'column',
};

const LinkWithMargin = styled(Link)`
  margin-left: ${({ theme }) => theme.space.x3};
`;

const StyledSanitizedHtml = styled(SanitizedHtml)`
  & > :last-child {
    display: inline;
  }
`;

export const ComplianceV1 = ({
  fieldName,
  compliance,
  fieldValidationRules,
  control,
  isLoggedIn = false,
  current,
}: ComplianceProps) => {
  const { t } = useTranslation();

  if (
    compliance.consentQuestion === undefined ||
    compliance.consentRequestIntroductionStatement === undefined
  ) {
    return null;
  }

  return (
    <Container>
      <Heading variant='2'>
        {compliance.title ?? t('compliance.data-protection')}
      </Heading>
      <Stack gapBetweenChildren='x4'>
        <div>
          <SanitizedHtml>
            {compliance.consentRequestIntroductionStatement}
          </SanitizedHtml>

          <DataProtectionField
            current={current}
            isLoggedIn={isLoggedIn}
            control={control}
            fieldName={fieldName}
            fieldValidationRules={fieldValidationRules}
            label={
              <StyledSanitizedHtml>
                {compliance.consentQuestion}
              </StyledSanitizedHtml>
            }
          />
        </div>

        <Paragraph>
          <Link
            setting='inline'
            variant='emphasised'
            href={compliance.privacyPolicyUrl}
          >
            {compliance.privacyPolicyText || t('compliance.privacy-policy')}
          </Link>
          {(compliance.showDataEnrichmentLink ?? true) && (
            <LinkWithMargin
              setting='inline'
              variant='emphasised'
              href={dataEnrichmentUrl}
            >
              {compliance.dataEnrichmentLinkText ||
                t('compliance.data-enrichment')}
            </LinkWithMargin>
          )}
        </Paragraph>
      </Stack>
    </Container>
  );
};
