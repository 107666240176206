import { Controller } from 'react-hook-form';
import {
  Checkbox,
  Paragraph,
  RadioButton,
  RadioGroup,
  Text,
} from '@beamery/lib-ds-components';
import { styled } from 'styled-components';
import { useTranslation } from '../../../../hooks/use-translation';
import type { SubmissionAnswer } from '../../form.interface';
import { isOptOutEnabled } from '../../../../env';
import type { DataProtectionFieldProps } from './compliance.interface';

const StyledCheckbox = styled(Checkbox)`
  & > span {
    white-space: unset;
    overflow: visible;
  }
`;

const options = {
  true: 'true',
  false: 'false',
};

const mapAnswerToString = (value: SubmissionAnswer) => {
  if (value === true) {
    return options.true;
  }
  if (value === false) {
    return options.false;
  }
  return null;
};

const mapStringToAnswer = (value: string) => {
  if (value === options.true) {
    return true;
  }
  if (value === options.false) {
    return false;
  }
  return null;
};

export const DataProtectionField = ({
  fieldName,
  fieldValidationRules,
  control,
  label,
  isLoggedIn,
  current,
}: DataProtectionFieldProps) => {
  const { t } = useTranslation();

  if (isOptOutEnabled && isLoggedIn) {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={fieldValidationRules.dataProtection}
        defaultValue={current ?? null}
        render={({
          field: { onChange, onBlur, value, ref, name },
          fieldState: { error },
        }) => (
          <RadioGroup
            isRequired
            onChange={(newValue) => {
              onChange(mapStringToAnswer(newValue));
            }}
            onBlur={onBlur}
            value={mapAnswerToString(value)}
            label={label}
            ref={ref}
            name={name}
            isInvalid={!!error}
            errorMessage={error?.message}
            orientation='horizontal'
          >
            <RadioButton value={options.true}>
              {t('compliance.consent.yes')}
            </RadioButton>
            <RadioButton value={options.false}>
              {t('compliance.consent.no')}
            </RadioButton>
          </RadioGroup>
        )}
      />
    );
  }

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={fieldValidationRules.dataProtection}
      defaultValue={false}
      render={({
        field: { onChange, onBlur, value, ref, name },
        fieldState: { error },
      }) => (
        <>
          <StyledCheckbox
            label={
              <>
                {label}{' '}
                <Text aria-hidden color='error'>
                  *
                </Text>
              </>
            }
            isRequired
            isSelected={value as boolean}
            onChange={onChange as (value: boolean) => void}
            onBlur={onBlur}
            ref={ref}
            name={name}
            isInvalid={!!error}
          />

          {error && (
            <Paragraph color='error' role='alert'>
              {error.message}
            </Paragraph>
          )}
        </>
      )}
    />
  );
};
