import { beameryHosts as hosts } from 'utils';
import * as captcha from '../../config/recaptcha.config';
import { injectScript } from '../../utils';

export const getCaptchaPublicKey = (domain: string) => {
  switch (domain) {
    case hosts.canaryAmazon:
    case hosts.canaryUs:
      return captcha.canaryKey;
    case hosts.previewUs:
      return captcha.previewKey;
    case hosts.productionAmazon:
    case hosts.productionUbs:
    case hosts.productionUs:
    case hosts.productionEu:
      return captcha.productionKey;
    case hosts.sandbox:
    case hosts.sandboxEu:
      return captcha.sandboxKey;
    default:
      return captcha.stagingKey;
  }
};

let initialised = false;

export const injectRecaptcha = (key: string) => {
  if (initialised) {
    return;
  }

  initialised = true;
  injectScript(`https://www.recaptcha.net/recaptcha/api.js?render=${key}`);
};
