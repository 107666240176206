import type { ReactNode } from 'react';
import hotToast, { Toaster } from 'react-hot-toast';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Notification } from '@beamery/lib-ds-components';

export const Toast = () => (
  <Toaster
    toastOptions={{
      style: {
        border: 'none',
        padding: '0',
        background: 'none',
        boxShadow: 'none',
      },
    }}
  />
);

interface ToastContentOptions {
  status?: 'error' | 'success' | 'info' | 'warning';
  label: ReactNode | ((t: TFunction) => ReactNode);
  description?: ReactNode | ((t: TFunction) => ReactNode);
  dismissible?: boolean;
}

interface ToastOptions extends ToastContentOptions {
  duration?: number;
  id: string;
}

const ToastContent = ({
  status,
  label,
  description,
  dismissible,
  toastId,
}: ToastContentOptions & { toastId: string }) => {
  const { t } = useTranslation();

  return (
    <Notification
      label={label instanceof Function ? label(t) : label}
      description={
        description instanceof Function ? description(t) : description
      }
      variant='toast'
      status={status}
      onPressClose={dismissible ? () => hotToast.dismiss(toastId) : undefined}
    />
  );
};

export const toast = (toastOptions: ToastOptions) => {
  const {
    status = 'info',
    label,
    description,
    duration = 6000,
    id,
    dismissible = true,
  } = toastOptions;
  hotToast(
    (t) => (
      <ToastContent
        label={label}
        description={description}
        status={status}
        dismissible={dismissible}
        toastId={t.id}
      />
    ),
    { duration, id },
  );
};

export const removeToast = (toastId?: string) => hotToast.remove(toastId);
