let initialised = false;

export const injectGTMScript = (gtmId?: string | null) => {
  if (!gtmId || initialised) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  const gtmScript = document.createElement('script');
  gtmScript.type = 'text/javascript';
  gtmScript.async = true;
  gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(gtmScript);

  // iframe
  const body = document.getElementsByTagName('body')[0];
  const gtmNoScript = document.createElement('noscript');
  const gtmIframe = document.createElement('iframe');
  gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
  gtmIframe.width = '0';
  gtmIframe.height = '0';
  gtmIframe.setAttribute('style', 'display:none;visibility:hidden');
  gtmNoScript.appendChild(gtmIframe);
  body.prepend(gtmNoScript);

  initialised = true;
};
