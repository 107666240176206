// note document.currentScript will be null if called from a callback or event listener
const getCurrentScriptUrlObject = () => {
  const currentScript = document.currentScript as HTMLScriptElement;
  if (currentScript) {
    return new URL(currentScript.src);
  }
  return null;
};

export const getCurrentScriptBaseDomain = () => {
  const url = getCurrentScriptUrlObject();
  if (url) {
    return url.host;
  }
  return '';
};
