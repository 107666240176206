import { Button as DSButton, Stack } from '@beamery/lib-ds-components';
import type { ButtonElement, FlexibleOptions } from 'form-definition';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/use-translation';

const StyledButton = styled(DSButton)`
  & > span {
    opacity: ${({ loading }) => (loading ? 0 : 1)};
  }
`;

interface ButtonsProps {
  action: ButtonElement['meta']['action'];
  loading: boolean;
  onReset: () => void;
  onGoBack?: () => void;
  showBackButton: boolean;
  showSubmitButton: boolean;
  flexibleOptions: FlexibleOptions | null;
}

export const Buttons = ({
  action,
  loading,
  onReset,
  onGoBack,
  showBackButton,
  showSubmitButton,
  flexibleOptions,
}: ButtonsProps) => {
  const { t } = useTranslation();

  const nextButtonText = flexibleOptions?.nextButtonText || t('buttons.next');
  const backButtonText = flexibleOptions?.backButtonText || t('buttons.back');
  const submitButtonText =
    flexibleOptions?.submitButtonText || t('buttons.submit');
  const restartButtonText =
    flexibleOptions?.restartButtonText || t('buttons.restart');

  return (
    <Stack direction='row' gapBetweenChildren='x4' justify='center'>
      {action === 'reset' ? (
        <StyledButton
          variant='secondary'
          type='button'
          loading={loading ? 'spinner' : undefined}
          onPress={onReset}
        >
          {restartButtonText}
        </StyledButton>
      ) : (
        <>
          {showBackButton && (
            <StyledButton
              type='button'
              variant='secondary'
              isDisabled={loading}
              onPress={onGoBack}
            >
              {backButtonText}
            </StyledButton>
          )}
          <StyledButton type='submit' loading={loading ? 'spinner' : undefined}>
            {showSubmitButton ? submitButtonText : nextButtonText}
          </StyledButton>
        </>
      )}
    </Stack>
  );
};
