import { useMemo, useState, forwardRef } from 'react';
import type { FieldError } from 'react-hook-form';
import type { VerboseError } from 'design-system-extended';
import { FileUploader } from 'design-system-extended';
import type { FileElement } from 'form-definition';
import {
  fileHasAllowedExtension,
  isFileSizeLargerThan,
  toWholeMebibytes,
} from 'utils';
import { useTranslation } from '../../../hooks/use-translation';

type MetaMessages = FileElement['meta']['messages'];

interface FileInputProps {
  label: string;
  acceptedTypes: string[];
  sizeLimit: number;
  value: File | null;
  name: string;
  onChange: (file: File | '') => void;
  onBlur?: () => void;
  error?: FieldError;
  isRequired?: boolean;
  messages?: MetaMessages;
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      label,
      acceptedTypes,
      sizeLimit,
      value,
      name,
      onChange,
      onBlur,
      error = null,
      isRequired,
      messages,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const [internalError, setInternalError] = useState<VerboseError | null>(
      null,
    );

    const handleChange = (file: File | null) => {
      if (file) {
        if (fileHasAllowedExtension(file, acceptedTypes)) {
          if (isFileSizeLargerThan(file, sizeLimit)) {
            setInternalError({
              error:
                messages?.sizeLimitLabel ?? t('file-uploader.size-limit.error'),
              additionalInfo:
                messages?.sizeLimitMessage ??
                t('file-uploader.size-limit.info', {
                  sizeLimit: toWholeMebibytes(sizeLimit),
                }),
            });
            onChange('');
          } else {
            setInternalError(null);
            onChange(file);
          }
        } else {
          setInternalError({
            error:
              messages?.fileTypesLabel ?? t('file-uploader.file-types.error'),
            additionalInfo:
              messages?.fileTypesMessage ??
              t('file-uploader.file-types.info', {
                acceptedTypes,
              }),
          });
          onChange('');
        }
      } else {
        setInternalError(null);
        onChange('');
      }
      onBlur?.();
    };

    const fileError = useMemo<VerboseError | null>(() => {
      if (internalError) {
        return internalError;
      }

      if (error && error.message) {
        return {
          error: error.message,
        };
      }

      return null;
    }, [internalError, error]);

    return (
      <FileUploader
        isDragAndDropEnabled
        ref={ref}
        name={name}
        fileError={fileError}
        fileInfo={
          value
            ? {
                filename: value.name,
                src: '',
              }
            : null
        }
        label={label}
        helpMessage={
          messages?.uploadGuidanceMessage ??
          t('file-uploader.upload-guidance', {
            sizeLimit: toWholeMebibytes(sizeLimit),
            acceptedTypes,
          })
        }
        dragAndDropText={
          messages?.dragAndDropText ?? t('file-uploader.drag-and-drop-text')
        }
        uploadButtonLabel={
          messages?.uploadButton ?? t('file-uploader.upload-button')
        }
        accept={acceptedTypes.join(',')}
        onFileSelection={handleChange}
        loadingLabel={messages?.loadingLabel ?? t('file-uploader.loading')}
        removeFileLabel={
          messages?.removeFileLabel ??
          t('file-uploader.remove-file-button.aria-label')
        }
        isRequired={isRequired}
      />
    );
  },
);
