import * as Sentry from '@sentry/react';
import type { ReactElement, ReactNode } from 'react';
import { GenericErrorPage } from '../generic-error-page';

export const SentryErrorBoundary = ({
  children,
  fallback = <GenericErrorPage />,
}: {
  children: ReactNode;
  fallback?: ReactElement;
}) => (
  <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
);
