import { Paragraph } from '@beamery/lib-ds-components';

interface ReadonlyInputProps {
  label: string;
  value?: string;
}

export const ReadonlyInput = ({ label, value }: ReadonlyInputProps) => (
  <div>
    <Paragraph variant='smallCompactEmphasised'>{label}</Paragraph>
    {value && <Paragraph>{value}</Paragraph>}
  </div>
);
