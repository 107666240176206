import { Button as DSButton } from '@beamery/lib-ds-components';
import type { ButtonElement } from 'form-definition';
import type { ReactNode } from 'react';
import styled from 'styled-components';

const StyledButton = styled(DSButton)`
  & > span {
    opacity: ${({ loading }) => (loading ? 0 : 1)};
  }
`;

interface ButtonProps {
  action: ButtonElement['meta']['action'];
  loading: boolean;
  onReset: () => void;
  children: ReactNode;
}

export const Button = ({ action, loading, onReset, children }: ButtonProps) => {
  switch (action) {
    case 'submit':
      return (
        <StyledButton type='submit' loading={loading ? 'spinner' : undefined}>
          {children}
        </StyledButton>
      );
    case 'reset':
      return (
        <DSButton type='button' onPress={onReset}>
          {children}
        </DSButton>
      );
    default:
      return null;
  }
};
