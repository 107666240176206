import {
  getServiceDomain as getServiceDomainUtil,
  getFrontierServiceUrl,
} from 'utils';
import { isDev, isEmbedded, stagingEnvironment } from '../env';

// Calculate frontierServiceUrl upfront as it relies on using document.currentScript for embedded
// which will be null in a callback or event listener
const frontierServiceUrl = getFrontierServiceUrl(
  stagingEnvironment,
  isDev,
  isEmbedded,
);

export const getServiceDomain = (service: string) =>
  getServiceDomainUtil(service, stagingEnvironment, isDev);

export const getFrontierApiUrl = (path: string) =>
  `${frontierServiceUrl}/${path}`;
