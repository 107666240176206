import type { ComplianceProps } from './compliance.interface';
import { ComplianceV1 } from './compliancev1';
import { ComplianceV2 } from './compliancev2';

export const Compliance = ({ compliance, ...rest }: ComplianceProps) => {
  if (compliance === undefined) {
    return null;
  }

  const ChildComponent = compliance.version === 2 ? ComplianceV2 : ComplianceV1;

  return <ChildComponent compliance={compliance} {...rest} />;
};
