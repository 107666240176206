import styled from 'styled-components';
import { useMemo } from 'react';
import { generateImageSrcs } from '../utils';

const StyledImage = styled.img`
  max-width: 100%;
  display: block;
  align-self: flex-start;
  margin-bottom: ${({ theme }) => theme.space.x4};
`;

export const Image = ({
  src: imgUrl,
  ...restProps
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const { src } = useMemo(
    () => generateImageSrcs({ url: imgUrl, width: 800 }),
    [imgUrl],
  );

  if (!imgUrl) {
    return null;
  }

  return <StyledImage src={src} {...restProps} />;
};
