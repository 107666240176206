import { Label, Text } from '@beamery/lib-ds-components';
import type { ComponentProps } from 'react';

interface LabelWithRequiredProps extends ComponentProps<typeof Label> {
  isRequired?: boolean;
}

export const LabelWithRequired = ({
  isRequired,
  children,
  ...rest
}: LabelWithRequiredProps) => (
  <Label {...rest}>
    {children}
    {isRequired && (
      <>
        {' '}
        <Text aria-hidden color='error'>
          *
        </Text>
      </>
    )}
  </Label>
);
