import { LoadingSpinner } from '@beamery/lib-ds-components';
import { theme } from '@beamery/lib-ds-theme';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { VanityUrlProvider } from 'candidate-facing-components';
import { ReactI18nextProvider } from 'react-i18next-helpers';
import { DesignSystemProvider } from 'design-system-extended';
import { OneTrustProvider } from './components/onetrust';
import { i18NextConfig } from './i18n';
import { Router } from './router';
import { SentryErrorTracking, SentryErrorBoundary } from './components/sentry';
import { MetricsProvider } from './components/metrics';

// This element is needed to be able to style the div that is injected by the DesignSystemProvider
const AppContainer = styled.main`
  width: 100%;
  min-height: 100%;
  word-break: break-word;

  > div {
    min-height: 100%;
    display: flex;
    flex-flow: column;
    align-items: stretch;
  }
`;

const GlobalRecaptchaStyles = createGlobalStyle`
  .grecaptcha-badge { 
    visibility: hidden; 
  }
`;

export const App = () => (
  <AppContainer>
    <DesignSystemProvider theme={theme}>
      <VanityUrlProvider>
        <SentryErrorTracking>
          <ReactI18nextProvider config={i18NextConfig}>
            <GlobalRecaptchaStyles />
            <Suspense fallback={<LoadingSpinner />}>
              <SentryErrorBoundary>
                <OneTrustProvider>
                  <MetricsProvider isEmbedded={false}>
                    <BrowserRouter>
                      <Router />
                    </BrowserRouter>
                  </MetricsProvider>
                </OneTrustProvider>
              </SentryErrorBoundary>
            </Suspense>
          </ReactI18nextProvider>
        </SentryErrorTracking>
      </VanityUrlProvider>
    </DesignSystemProvider>
  </AppContainer>
);
