import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import type { ErrorEvent } from '@sentry/types';
import { getEnvironmentName, LoggerProvider } from 'utils';
import { useCurrentDomain } from 'candidate-facing-components';
import {
  sentryDenyUrls,
  sentryDsn,
  sentrySampleRate,
} from '../../config/sentry.config';
import { version, sentryEnabled } from '../../env';

const shouldSendError = (event: ErrorEvent, hint: Sentry.EventHint) => {
  if (
    typeof hint.originalException === 'string' &&
    hint.originalException === 'Timeout'
  ) {
    // reCAPTCHA internal timeout
    return false;
  }

  if (hint.originalException instanceof Error) {
    if (
      hint.originalException.name === 'TypeError' &&
      hint.originalException.message === 'Illegal invocation'
    ) {
      // navigator.sendBeacon can be called incorrectly by 3rd party scripts
      return false;
    }

    if (
      hint.originalException.name === 'NetworkError' &&
      hint.originalException.message === 'A network error occurred.'
    ) {
      // Safari throws a generic network error when Launch Darkly makes XHR request in window unload event
      return false;
    }
  }

  if (
    event.exception?.values?.[0]?.stacktrace?.frames?.some((f) =>
      f.filename?.includes(`https://www.googletagmanager.com/gtm.js`),
    )
  ) {
    // Errors from customer's Google Tag Manager scripts
    return false;
  }

  return true;
};

export const SentryErrorTracking = ({ children }: { children: ReactNode }) => {
  const { parentDomain } = useCurrentDomain();

  useEffect(() => {
    if (sentryEnabled) {
      Sentry.init({
        dsn: sentryDsn,
        environment: getEnvironmentName(parentDomain),
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: sentrySampleRate,
        denyUrls: sentryDenyUrls,
        release: version ? `candidate-experience@${version}` : undefined,
        beforeSend: (event, hint) =>
          shouldSendError(event, hint) ? event : null,
      });
    }

    return () => {
      void Sentry.close();
    };
  }, [parentDomain]);

  const logError = useCallback((error: unknown, meta?: unknown) => {
    Sentry.withScope((scope) => {
      if (meta) {
        scope.setExtra('Meta', meta);
      }
      Sentry.captureException(error, scope);
    });
  }, []);

  const logInfo = useCallback((message: string, meta?: unknown) => {
    Sentry.withScope((scope) => {
      if (meta) {
        scope.setExtra('Meta', meta);
      }
      Sentry.captureMessage(message, scope);
    });
  }, []);

  return (
    <LoggerProvider logError={logError} logInfo={logInfo}>
      {children}
    </LoggerProvider>
  );
};
