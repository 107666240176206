import { theme } from '@beamery/lib-ds-theme';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { createTheme } from '@beamery/canex-theming';
import { DesignSystemProvider } from '../design-system-provider';

interface ThemedDesignSystemProviderProps {
  baseColor: string | null | undefined;
  children: ReactNode;
}

export const ThemedDesignSystemProvider = ({
  baseColor,
  children,
}: ThemedDesignSystemProviderProps) => {
  const compiledTheme = useMemo(
    () =>
      baseColor
        ? createTheme({ baseColor, enableAccessibleTheming: true })
        : theme,
    [baseColor],
  );
  return (
    <DesignSystemProvider theme={compiledTheme}>
      {children}
    </DesignSystemProvider>
  );
};
