import styled, { css, keyframes } from 'styled-components';
import {
  Button,
  FileUploaderControl,
  Flex,
  Notification,
  Link,
  Text,
} from '@beamery/lib-ds-components';
import { LabelWithRequired } from '../label-with-required';

export const spinnerRotate = keyframes`
from {
  transform: rotate(0deg)
}

to {
  transform: rotate(360deg)
}
`;

export const Container = styled(Flex)`
  position: relative;
  gap: ${({ theme }) => theme.space.x1};
`;
Container.defaultProps = {
  direction: 'column',
};

export const UploadLabel = styled(LabelWithRequired)`
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.m};
`;

export const HelpText = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  flex: 1;
  min-width: 220px;
`;
HelpText.defaultProps = {
  fontSize: 'xs',
  lineHeight: 'xs',
};

export const UploaderControl = styled(FileUploaderControl)`
  flex-shrink: 0;

  svg {
    ${(props) =>
      props.startIcon === 'Loading' &&
      css`
        animation: ${spinnerRotate} 1.15s
          ${props.theme.transitions.easing['ease-in-out']} infinite;
      `};
  }
`;

export const FileStatusContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.backgrounds.default};
  border-radius: ${({ theme }) => theme.radii.x1_5};
  padding: ${({ theme }) => theme.space.x1} ${({ theme }) => theme.space.x2};
`;
FileStatusContainer.defaultProps = {
  justify: 'space-between',
  align: 'center',
};

export const FileLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.lineHeights.xs};
`;

export const FileErrorNotification = styled(Notification)`
  background-color: ${({ theme }) => theme.colors.backgrounds.none};
  padding: ${({ theme }) => theme.space.x1} 0;
`;

export const RemoveFileButton = styled(Button)`
  padding: 0;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.backgrounds.none};
`;
RemoveFileButton.defaultProps = {
  variant: 'secondary',
  startIcon: 'Delete',
};
