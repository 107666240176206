import { useEffect, useState } from 'react';
import type { I18nextProviderProps } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import { useLogger } from 'utils';
import { initialiseReactI18next } from '../../react-i18next-helpers';
import type { ReactI18NextConfig } from '../../react-i18next-helpers';

export interface ReactI18NextProviderProps
  extends Omit<I18nextProviderProps, 'i18n'> {
  config: ReactI18NextConfig;
}

export const ReactI18nextProvider = ({
  config,
  ...providerProps
}: ReactI18NextProviderProps) => {
  const logger = useLogger();

  const [translationsConfig] = useState(() =>
    initialiseReactI18next(config, logger),
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const onInit = () => setLoading(false);
    if (translationsConfig.isInitialized) {
      onInit();
      return undefined;
    }
    translationsConfig.on('initialized', onInit);
    return () => translationsConfig.off('initialized', onInit);
  }, [translationsConfig]);

  // If you try to access i18n context while the instance is loading, can enter a suspense loop
  return loading ? null : (
    <I18nextProvider
      i18n={translationsConfig}
      i18nIsDynamicList
      {...providerProps}
    />
  );
};
