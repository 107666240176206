import type {
  FlexibleOptions,
  PublicFormDefinition,
  FormInputElement,
  Page,
} from 'form-definition';
import type { TFunction } from 'i18next';
import { isInputElement } from 'form-definition';
import type { RegisterOptions } from 'react-hook-form';
import isEmail from 'validator/es/lib/isEmail';
import type { Submission } from './form.interface';

export type Rules = Omit<
  RegisterOptions<Submission, string>,
  | 'value'
  | 'valueAsNumber'
  | 'valueAsDate'
  | 'setValueAs'
  | 'disabled'
  | 'onChange'
  | 'onBlur'
>;

const getInputElements = (page: Page): Array<FormInputElement> =>
  page.elements.filter(isInputElement);

const getFieldValidation = (
  element: FormInputElement,
  t: TFunction<'candidate-facing-components'>,
  flexibleOptions: FlexibleOptions | null,
): Rules => {
  const requiredMessage: string =
    flexibleOptions?.requiredFieldErrorMessage || t('form.validation.required');
  const required = {
    value: element.required,
    message: requiredMessage,
  };

  switch (element.type) {
    case 'text':
      return {
        required,
      };
    case 'email':
      return {
        required,
        validate: (value) => {
          if (!value || (typeof value === 'string' && isEmail(value))) {
            return true;
          }
          return (
            element.meta.messages?.invalidFormatMessage ??
            t('form.validation.email')
          );
        },
      };
    default:
      return {
        required,
      };
  }
};

const getStandardValidation = (
  formDefinition: PublicFormDefinition,
  t: TFunction<'candidate-facing-components'>,
  flexibleOptions: FlexibleOptions | null,
): Record<string, Rules> => {
  const hasComplianceBlock = formDefinition.pages
    .flatMap((page) => page.elements)
    .some((element) => element.type === 'dataProtection');
  const isAuthenticated = formDefinition.authenticated;

  if (!hasComplianceBlock) {
    return {};
  }

  return isAuthenticated
    ? {
        dataProtection: {
          validate: (value) => {
            if (typeof value === 'boolean') {
              return true;
            }
            return (
              flexibleOptions?.requiredFieldErrorMessage ||
              t('form.validation.required')
            );
          },
        },
      }
    : {
        dataProtection: {
          required: {
            value: true,
            message:
              flexibleOptions?.requiredFieldErrorMessage ||
              t('form.validation.required'),
          },
        },
      };
};

export const toFieldValidationRules = (
  formDefinition: PublicFormDefinition,
  t: TFunction<'candidate-facing-components'>,
): Record<string, Rules> => {
  const { flexibleOptions } = formDefinition;

  const inputElements = formDefinition.pages.flatMap(getInputElements);
  const standardValidation = getStandardValidation(
    formDefinition,
    t,
    flexibleOptions,
  );

  return inputElements.reduce<Record<string, Rules>>(
    (formFields, element) => ({
      ...formFields,
      [element.field]: getFieldValidation(element, t, flexibleOptions),
    }),
    standardValidation,
  );
};
