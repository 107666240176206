import styled from 'styled-components';
import { maxFormWidth } from 'candidate-facing-components';

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `${theme.space.x18} 0`};
  width: 100%;
`;

export const FormWrapper = styled.div`
  padding: ${({ theme }) => theme.space.x6};
  background-color: ${({ theme }) => theme.colors.backgrounds.onGray};
  border-radius: ${({ theme }) => theme.radii.x2};
  width: 100%;
  max-width: ${({ theme }) =>
    `calc(${maxFormWidth} + ${theme.space.x4} + ${theme.space.x4})`};
`;
