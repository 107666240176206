import { isDev, isEmbedded } from '../../env';

export const shouldRedirect = (vanityUrl?: string): vanityUrl is string => {
  if (
    !isDev &&
    !isEmbedded &&
    vanityUrl &&
    vanityUrl !== window.location.host
  ) {
    return true;
  }
  return false;
};

export const replaceLocation = (url: string) => window.location.replace(url);
