import { useTranslation } from 'react-i18next';
import { EmptyState } from '@beamery/lib-ds-components';

export const GenericErrorPage = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      heading={t('candidate-experience.generic-error-page.heading')}
      content={t('candidate-experience.generic-error-page.body')}
    />
  );
};
