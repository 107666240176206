import { createContext, useMemo } from 'react';
import { LoadingSpinner } from '@beamery/lib-ds-components';
import { useAsync } from 'react-use';
import { parentDomain } from 'utils';
import { isVanityDomain as isAVanityDomain } from '../../utils/url';
import type {
  VanityUrlProviderProps,
  VanityUrlContextValue,
} from './vanity-urls.interface';

const defaultValue: VanityUrlContextValue = {
  isVanityDomain: false,
  parentDomain,
};

export const VanityUrlContext = createContext(defaultValue);

// see ../../../docs/vanity-urls for more details
export const VanityUrlProvider = ({ children }: VanityUrlProviderProps) => {
  const isVanityDomain = isAVanityDomain();

  const { loading, value: originalDomain } = useAsync(async () => {
    if (!isVanityDomain) return null;

    const response = await fetch(window.location.href, { method: 'HEAD' });

    return response.headers.get('x-vanity-original-instance-domain');
  }, [isVanityDomain]);

  const value = useMemo<VanityUrlContextValue>(
    () => ({
      isVanityDomain,
      parentDomain: originalDomain || parentDomain,
    }),
    [originalDomain, isVanityDomain],
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <VanityUrlContext.Provider value={value}>
      {children}
    </VanityUrlContext.Provider>
  );
};
