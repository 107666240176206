import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space.x4} ${theme.space.x6} 0`};
  background: ${({ theme }) => theme.colors.backgrounds.default};

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    padding: 0;
    background: initial;
  }
`;
