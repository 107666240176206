import { forwardRef } from 'react';
import { CoreTypeahead } from './core-typeahead';
import type { TypeaheadProps } from './core-typeahead/core-typeahead.interface';
import { searchUniversities } from './university-search';

type UniversityPickerProps = Omit<
  TypeaheadProps,
  'allowCreate' | 'loadOptions' | 'searchMinimumCharacters'
>;
const UniversityPickerComponent = (
  props: UniversityPickerProps,
  ref?: React.ForwardedRef<HTMLInputElement> | undefined,
) => (
  <CoreTypeahead
    {...props}
    ref={ref}
    allowCreate
    loadOptions={(inputValue) => searchUniversities(inputValue)}
  />
);

export const UniversityPicker = forwardRef(UniversityPickerComponent);
