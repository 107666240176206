import { Select as DSSelect } from '@beamery/lib-ds-components';
import type { FlexibleOptions, SelectElement } from 'form-definition';
import { forwardRef, useMemo } from 'react';
import type { FieldError } from 'react-hook-form';
import { useTranslation } from '../../../hooks/use-translation';

interface SelectProps {
  multi: boolean;
  label: string;
  options: SelectElement['meta']['options'];
  required: boolean;
  name: string;
  onChange: (items: string | string[] | null) => void;
  onBlur: () => void;
  error?: FieldError;
  value: string | string[] | null;
  flexibleOptions: FlexibleOptions | null;
  sortOptionsAlphabetically: boolean;
}

interface SelectedItem {
  id: string;
  label: string;
}

export const MIN_ITEMS_FOR_FILTER = 11;

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      multi,
      label,
      options,
      required,
      name,
      onChange,
      onBlur,
      error,
      value,
      flexibleOptions,
      sortOptionsAlphabetically,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const placeholder =
      (multi
        ? flexibleOptions?.multiSelectPlaceholder
        : flexibleOptions?.singleSelectPlaceholder) ||
      t('select-input.control-label');

    const filterText =
      (multi
        ? flexibleOptions?.multiSelectFilterText
        : flexibleOptions?.singleSelectFilterText) || t('select-input.filter');

    const defaultItems = useMemo(
      () =>
        options
          .map((option) => ({
            id: option.value,
            label: option.label,
          }))
          .sort((a, b) => {
            if (sortOptionsAlphabetically) {
              return a.label.localeCompare(b.label, undefined, {
                numeric: true,
              });
            }
            return 0;
          }),
      [options, sortOptionsAlphabetically],
    );

    const standardProps = {
      ref,
      name,
      defaultItems,
      listBoxLabel: placeholder,
      controlLabel: placeholder,
      label,
      errorMessage: error?.message,
      isRequired: required,
      fullWidth: true,
      onClose: onBlur,
      dataAutomation: 'convert-form-select',
      isInModal: true,
      filterInput:
        options.length >= MIN_ITEMS_FOR_FILTER
          ? {
              placeholder: filterText,
              fullWidth: true,
            }
          : undefined,
    };

    return multi ? (
      <DSSelect
        {...standardProps}
        selectionMode='multiple'
        selectedPrefix={
          flexibleOptions?.multiSelectSelectedText ||
          t('select-input.selected-prefix')
        }
        onSelectionChange={(items: SelectedItem[]) => {
          onChange(items.map((item) => item.id));
        }}
        defaultSelectedItems={
          Array.isArray(value)
            ? value.map((entry) => ({ id: entry, label: '' }))
            : []
        }
      />
    ) : (
      <DSSelect
        {...standardProps}
        selectionMode='single'
        onSelectionChange={(item?: SelectedItem) => {
          onChange(item?.id ?? '');
        }}
        defaultSelectedItem={
          typeof value === 'string' ? { id: value, label: '' } : undefined
        }
      />
    );
  },
);
