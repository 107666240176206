import {
  getFrontierServiceUrl,
  isVanityDomain as isVanityDomainUtil,
} from 'utils';
import { isDev, isEmbedded, stagingEnvironment } from '../env';

// Calculate frontierServiceUrl upfront as it relies on using document.currentScript for embedded
// which will be null in a callback or event listener
export const frontierServiceUrl = getFrontierServiceUrl(
  stagingEnvironment,
  isDev,
  isEmbedded,
);

export const isVanityDomain = () => isVanityDomainUtil(isEmbedded);
