import type { CallbackError, ResourceKey, InitOptions } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import type { ReactI18NextConfig } from 'react-i18next-helpers';

export const i18nextInitConfig: Partial<InitOptions> = {
  ns: 'candidate-facing-components',
  defaultNS: 'candidate-facing-components',
};

export const i18NextConfig: ReactI18NextConfig = {
  modulesToUse: [
    resourcesToBackend((language, namespace, callback) => {
      import(`../locales/${language}/${namespace}.json`)
        .then((resources: ResourceKey) => {
          callback(null, resources);
        })
        .catch((error: CallbackError) => {
          callback(error, null);
        });
    }),
  ],
  options: i18nextInitConfig,
};
