import type { UseFormSetValue } from '@beamery/lib-ds-components';
import { LoadingSpinner, Link } from '@beamery/lib-ds-components';
import { Form } from 'candidate-facing-components';
import type { Submission } from 'candidate-facing-components';
import type { FileElement, PublicFormDefinition } from 'form-definition';
import { useAsync } from 'react-use';
import { toast, ThemedDesignSystemProvider } from 'design-system-extended';
import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useLogger, assignLocation } from 'utils';
import { v4 as uuid } from 'uuid';
import { PageNotFound } from '../page-not-found';
import { getFormBySlug, getFormById, submitForm } from '../../api/form';
import { useOneTrustCallback, useOneTrustSettings } from '../onetrust';
import {
  createTemporaryGoogleAnalyticsFn,
  injectGAScript,
} from '../../utils/analytics';
import { injectGTMScript } from '../../utils/google-tag-manager';
import { CustomDomainCandidateFormPagePath } from '../../paths';
import { CandidateExperienceNav } from '../candidate-experience-nav';
import { getAuthenticationUrl, getPortalUrl } from '../../utils/candidate-auth';
import { AuthError } from '../../api/candidate-auth';
import { useRecaptcha } from '../../hooks/use-recaptcha';
import { isEmbedded } from '../../env';
import { useMetrics } from '../metrics';
import { replaceLocation, shouldRedirect } from './utils';
import { FormWrapper, PageWrapper } from './candidate-form.styles';
import type { CandidateFormProps } from './candidate-form.interface';

const getVanityUrl = (formDefinition: PublicFormDefinition) =>
  formDefinition?.companySettings.vanityUrls?.[0];

const handleRedirects = ({
  formDefinition,
  formSlug,
}: {
  formDefinition: PublicFormDefinition;
  formSlug: string;
}) => {
  const vanityUrl = getVanityUrl(formDefinition);

  if (shouldRedirect(vanityUrl)) {
    const newPath = generatePath(CustomDomainCandidateFormPagePath, {
      formSlug,
    });

    replaceLocation(`https://${vanityUrl}${newPath}${window.location.search}`);
    return true;
  }

  if (formDefinition.settings.authenticated && !formDefinition.authenticated) {
    assignLocation(getAuthenticationUrl(formSlug));
    return true;
  }

  return false;
};

const renderContent = (form: JSX.Element) => (
  <PageWrapper>
    <FormWrapper>{form}</FormWrapper>
  </PageWrapper>
);

const getCandidateId = () => {
  const candidateIdFromStorage = localStorage.getItem('convert-candidate-id');
  if (candidateIdFromStorage) {
    return candidateIdFromStorage;
  }

  const newCandidateId = uuid();
  localStorage.setItem('convert-candidate-id', newCandidateId);
  return newCandidateId;
};

export const CandidateForm = ({
  companySlug,
  formSlug = '',
  companyId,
  formId,
  notFoundComponent = <PageNotFound />,
  loadingComponent = <LoadingSpinner />,
  contentComponent = renderContent,
  page,
  onPageChange,
  urlParameterString,
}: CandidateFormProps) => {
  const { t } = useTranslation();
  const metrics = useMetrics();
  const logger = useLogger();

  const {
    loading,
    error: formLoadError,
    value: {
      formDefinition = undefined,
      navigationItems = [],
      redirecting = false,
    } = {},
  } = useAsync(async () => {
    let result;
    const candidateId = getCandidateId();

    if (formId && companyId) {
      result = await getFormById({
        formId,
        companyId,
        candidateId,
        metrics,
      });
    } else {
      result = await getFormBySlug({
        formSlug,
        companySlug,
        candidateId,
        metrics,
      });
    }

    if (
      handleRedirects({
        formDefinition: result.formGetPublic,
        formSlug,
      })
    ) {
      return { redirecting: true };
    }

    return {
      formDefinition: result.formGetPublic,
      navigationItems: result.navigationItems.map(
        ({ id, title, url, icon }) => ({
          id,
          title,
          href: url,
          icon,
        }),
      ),
    };
  }, [companySlug, formSlug, companyId, formId]);

  const recaptchaKey = useRecaptcha(
    formDefinition?.companySettings.recaptchaEnabled ?? false,
  );
  const isLoggedIn = !!formDefinition?.authenticated;

  useOneTrustSettings(formDefinition?.companySettings.cookieSettings ?? null);
  useOneTrustCallback('C0002', () => {
    injectGAScript(formDefinition?.companySettings.gaKey);
    injectGTMScript(formDefinition?.companySettings.gtmKey);
  });

  useEffect(() => {
    createTemporaryGoogleAnalyticsFn();
  }, []);

  useEffect(() => {
    if (isEmbedded) return;

    if (formDefinition) {
      document.title = formDefinition.settings.externalName;
    } else {
      document.title = t('candidate-experience.loading');
    }
  }, [formDefinition, t]);

  useEffect(() => {
    if (formLoadError) {
      logger.error(formLoadError, { companySlug, formSlug });
    }
  }, [formLoadError, logger, companySlug, formSlug]);

  if (loading || redirecting) {
    return loadingComponent;
  }

  if (formLoadError || !formDefinition) {
    return notFoundComponent;
  }

  if (page >= formDefinition.pages.length) {
    return notFoundComponent;
  }

  const handleSubmit = async (
    data: Submission,
    setFormValue?: UseFormSetValue<Submission>,
  ) => {
    const nextFormPage = formDefinition.pages[page + 1];
    if (nextFormPage.pageType === 'form') {
      onPageChange(page + 1);
      return;
    }

    const { id, settings, authenticated, companySettings } = formDefinition;

    try {
      await submitForm({
        config: {
          id,
          slug: settings.slug,
          companySlug: companySettings.companySlug,
          companyId: companySettings.companyId,
          recaptchaKey,
          candidateId: getCandidateId(),
        },
        submission: data,
        isAuthenticated: !!authenticated,
        metrics,
        urlParameterString: urlParameterString ?? '',
      });

      const files = formDefinition.pages
        .flatMap(({ elements }) => elements)
        .filter((element): element is FileElement => element.type === 'file');

      // strip file references out on successful submission to prevent any re-upload of files
      files.forEach((file) => {
        const fieldValue = data[file.id];
        if (fieldValue instanceof File && setFormValue) {
          setFormValue(file.id, fieldValue.name);
        }
      });

      const successPage = formDefinition.pages.find(
        (p) => p.pageType === 'success',
      );

      if (isLoggedIn && successPage?.onSubmit === 'alert') {
        toast({
          status: 'success',
          label:
            formDefinition.flexibleOptions?.submitToastMessage ||
            t('candidate-experience.submit-toast.default'),
          id: 'form_success',
          dismissible: false,
        });
      } else if (successPage?.onSubmit === 'login') {
        const emailField = formDefinition.pages[page].elements.find(
          (e) => e.type === 'email',
        );
        if (!emailField) {
          throw new Error('Could not find email for login');
        }

        const email = data[emailField.id];
        if (typeof email !== 'string') {
          throw new Error('Email field value not a string');
        }

        assignLocation(
          getPortalUrl({
            email,
            customDomain: getVanityUrl(formDefinition),
          }),
        );
      } else {
        onPageChange(page + 1);
      }
    } catch (error) {
      if (error instanceof AuthError) {
        toast({
          status: 'error',
          label: (
            <Trans
              t={t}
              i18nKey='candidate-experience.authentication.re-authenticate'
            >
              We could not submit the form because you have been signed out.
              Please go back to the{' '}
              <Link
                setting='inline'
                variant='emphasised'
                href={getAuthenticationUrl(formSlug)}
              >
                sign in page
              </Link>
              and try again
            </Trans>
          ),
          id: 'token_error',
          dismissible: false,
          duration: Infinity,
        });
      } else {
        throw error;
      }
    }
  };

  const handleGoBack = () => {
    onPageChange(page - 1);
  };

  const handleReset = () => {
    onPageChange(0);
  };

  return (
    <ThemedDesignSystemProvider
      baseColor={formDefinition.companySettings.accentColor}
    >
      <CandidateExperienceNav
        isLoggedIn={isLoggedIn}
        logoUrl={formDefinition.companySettings.companyLogoUrl}
        companyName={formDefinition.companySettings.companyName}
        navItems={navigationItems}
      >
        {contentComponent(
          <Form
            formDefinition={formDefinition}
            page={page}
            onSubmit={handleSubmit}
            onGoBack={handleGoBack}
            onReset={handleReset}
            isLoggedIn={isLoggedIn}
          />,
        )}
      </CandidateExperienceNav>
    </ThemedDesignSystemProvider>
  );
};
