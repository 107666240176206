import { VisuallyHidden, Text } from '@beamery/lib-ds-components';
import cuid from 'cuid';
import type { ChangeEvent, ReactNode } from 'react';
import { forwardRef, useRef, useMemo } from 'react';
import type { FileInfo, FileUploaderProps } from './file-uploader.interface';
import {
  Container,
  FileErrorNotification,
  FileLink,
  FileStatusContainer,
  HelpText,
  RemoveFileButton,
  UploadLabel,
  UploaderControl,
} from './file-uploader.styles';

const FileName = ({ fileInfo }: { fileInfo: FileInfo }) =>
  fileInfo.src ? (
    <FileLink href={fileInfo.src}>{fileInfo.filename}</FileLink>
  ) : (
    <Text fontSize='s'>{fileInfo.filename}</Text>
  );

interface FileStatusProps
  extends Pick<
    FileUploaderProps,
    'fileInfo' | 'fileError' | 'loading' | 'removeFileLabel' | 'loadingLabel'
  > {
  onRemoveFile: () => void;
  isDisabled?: boolean;
}

const FileStatus = ({
  loading,
  loadingLabel,
  fileInfo,
  isDisabled,
  removeFileLabel,
  onRemoveFile,
}: FileStatusProps) => {
  if (loading) {
    return <VisuallyHidden role='alert'>{loadingLabel}</VisuallyHidden>;
  }
  return (
    <>
      {fileInfo != null && (
        <FileStatusContainer>
          <FileName fileInfo={fileInfo} />
          <RemoveFileButton
            isDisabled={isDisabled}
            aria-label={removeFileLabel}
            onPress={onRemoveFile}
          />
        </FileStatusContainer>
      )}
    </>
  );
};

const VisuallyHiddenWrapper = ({
  isHidden,
  children,
}: {
  isHidden: boolean;
  children: ReactNode;
}) =>
  isHidden ? (
    <VisuallyHidden role='alert'>{children}</VisuallyHidden>
  ) : (
    children
  );

export const FileUploader = forwardRef<HTMLInputElement, FileUploaderProps>(
  (
    {
      id,
      label,
      removeFileLabel,
      uploadButtonLabel,
      dragAndDropText,
      loadingLabel,
      helpMessage,
      fileInfo,
      fileError,
      loading,
      isRequired = false,
      onFileSelection,
      isDisabled = false,
      isDragAndDropEnabled = false,
      ...rest
    }: FileUploaderProps,
    ref,
  ) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const fileUploaderId = useMemo(() => id ?? cuid(), [id]);
    const errorSectionId = `${fileUploaderId}-error`;

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.currentTarget;
      if (files && files[0]) {
        onFileSelection?.(files[0]);
      }
    };

    const handleRemoveFile = () => {
      onFileSelection?.(null);
      if (fileInputRef?.current) {
        fileInputRef.current.value = '';
      }
    };

    const handleFileDrop = (files: File[]) => {
      if (files) onFileSelection?.(files[0]);
    };

    return (
      <Container aria-busy={loading}>
        <UploadLabel htmlFor={fileUploaderId} isRequired={isRequired}>
          {label}
        </UploadLabel>
        {helpMessage && <HelpText>{helpMessage}</HelpText>}
        <FileStatus
          loading={loading}
          fileError={fileError}
          fileInfo={fileInfo}
          onRemoveFile={handleRemoveFile}
          removeFileLabel={removeFileLabel}
          loadingLabel={loadingLabel}
          isDisabled={isDisabled || loading}
        />
        <VisuallyHiddenWrapper isHidden={!!fileInfo}>
          <UploaderControl
            {...rest}
            id={fileUploaderId}
            multiple={false}
            isDragAndDropEnabled={isDragAndDropEnabled}
            dropZoneText={dragAndDropText}
            variant='secondaryOnGray'
            onChange={handleOnChange}
            onFileSelect={handleFileDrop}
            ref={(elem) => {
              if (ref) {
                if ('current' in ref) {
                  // eslint-disable-next-line no-param-reassign
                  ref.current = elem;
                } else {
                  ref(elem);
                }
              }
              fileInputRef.current = elem;
            }}
            required={isRequired}
            startIcon={loading ? 'Loading' : 'Attachment'}
            isDisabled={isDisabled || loading}
            aria-invalid={!!fileError}
            aria-errormessage={errorSectionId}
          >
            {uploadButtonLabel}
          </UploaderControl>
        </VisuallyHiddenWrapper>
        {fileError && !loading && (
          <FileErrorNotification
            status='error'
            variant='banner'
            id={errorSectionId}
            label={fileError.error}
            description={fileError?.additionalInfo}
            showIcon={false}
          />
        )}
      </Container>
    );
  },
);
