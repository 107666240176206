const mebibyte = 1024 * 1024;

export const toWholeMebibytes = (fileSizeBytes: number) =>
  Math.round(fileSizeBytes / mebibyte);

export const mebibytesToBytes = (mebibytes: number) => mebibytes * mebibyte;

export const isFileSizeLargerThan = (file: File, sizeLimit: number): boolean =>
  file.size > sizeLimit;

export const fileHasAllowedExtension = (
  file: File,
  acceptedTypes: string[],
) => {
  const lowerName = file.name.toLocaleLowerCase();
  return acceptedTypes.some((type) => lowerName.endsWith(type));
};
