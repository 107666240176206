export const isDev = process.env.NODE_ENV === 'development';

export const stagingEnvironment = process.env.STAGING_ENVIRONMENT ?? 'spark';
export const fakeServer = isDev && process.env.FAKE_SERVER === 'true';

export const version = process.env.VERSION;

export const sentryEnabled = process.env.SENTRY_DISABLED !== 'true';

export const isEmbedded = process.env.RENDER_MODE === 'embedded';
