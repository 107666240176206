export const noop = () => {
  // no-op
};

export const noopAsync = (): Promise<void> => Promise.resolve();

export const delay = (ms: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
