import type { SocialMedia } from 'form-definition';
import { Link as DSLink, Stack } from '@beamery/lib-ds-components';
import styled from 'styled-components';
import {
  facebookUrl,
  twitterUrl,
  linkedinUrl,
  instagramUrl,
  youtubeUrl,
  glassdoorUrl,
} from '../../../../config/social.config';
import FacebookIcon from './facebook.svg';
import LinkedinIcon from './linkedin.svg';
import TwitterIcon from './twitter.svg';
import YoutubeIcon from './youtube.svg';
import InstagramIcon from './instagram.svg';
import GlassdoorIcon from './glassdoor.svg';
import WeChatIcon from './wechat.svg';

export interface SocialIconsProps {
  socialMedia?: SocialMedia | null;
}

interface IconWithLinkProps {
  href: string;
  children: React.ReactNode;
  service: string;
  shouldShow: boolean;
}

const Link = styled(DSLink)`
  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.text.secondary};
  }

  &:hover svg {
    fill: ${({ theme }) => theme.colors.text.default};
  }
`;

const IconWithLink = ({
  href,
  service,
  shouldShow = false,
  children,
}: IconWithLinkProps) => {
  if (!shouldShow) return null;

  return (
    <Link href={href} title={service}>
      {children}
    </Link>
  );
};

export const SocialIcons = ({ socialMedia = {} }: SocialIconsProps) => {
  if (!socialMedia || Object.keys(socialMedia).length === 0) return null;
  return (
    <Stack gapBetweenChildren='x6' direction='row'>
      <IconWithLink
        href={`${facebookUrl}/${socialMedia.facebook}`}
        shouldShow={!!socialMedia.facebook}
        service='Facebook'
      >
        <FacebookIcon />
      </IconWithLink>
      <IconWithLink
        href={`${twitterUrl}/${socialMedia.twitter}`}
        shouldShow={!!socialMedia.twitter}
        service='X'
      >
        <TwitterIcon />
      </IconWithLink>
      <IconWithLink
        href={`${linkedinUrl}/${socialMedia.linkedin}`}
        shouldShow={!!socialMedia.linkedin}
        service='Linkedin'
      >
        <LinkedinIcon />
      </IconWithLink>
      <IconWithLink
        href={`${instagramUrl}/${socialMedia.instagram}`}
        shouldShow={!!socialMedia.instagram}
        service='Instagram'
      >
        <InstagramIcon />
      </IconWithLink>
      <IconWithLink
        href={`${youtubeUrl}/${socialMedia.youtube}`}
        shouldShow={!!socialMedia.youtube}
        service='YouTube'
      >
        <YoutubeIcon />
      </IconWithLink>
      <IconWithLink
        href={`${glassdoorUrl}/${socialMedia.glassdoor}`}
        shouldShow={!!socialMedia.glassdoor}
        service='Glassdoor'
      >
        <GlassdoorIcon />
      </IconWithLink>
      <IconWithLink
        href={socialMedia.wechat ?? ''}
        shouldShow={!!socialMedia.wechat}
        service='WeChat'
      >
        <WeChatIcon />
      </IconWithLink>
    </Stack>
  );
};
