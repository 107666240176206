import { normalizeAccentText } from 'utils';
import type { TypeaheadOption } from './core-typeahead/core-typeahead.interface';

const maxResults = 20;

const splitOnSeparators = (text: string) => text.split(/[ \u200B]/g);

const extractKeywords = (text: string) =>
  splitOnSeparators(normalizeAccentText(text.toLowerCase()));

interface UniversityForSearch {
  name: string;
  keywords: string[];
  sanitisedName: string;
}

const getUniversitiesForSearch = (() => {
  let universitiesForSearch: UniversityForSearch[] | null = null;

  return async () => {
    if (!universitiesForSearch) {
      const universityData = await import('../../../data/universities.json');
      universitiesForSearch = universityData.default.map((uni) => ({
        name: uni,
        keywords: extractKeywords(uni),
        sanitisedName: normalizeAccentText(uni.toLowerCase()),
      }));
    }

    return universitiesForSearch;
  };
})();

const getRank = (result: UniversityForSearch, sanitisedSearch: string) => {
  if (result.sanitisedName === sanitisedSearch) {
    return 0;
  }

  if (result.sanitisedName.startsWith(sanitisedSearch)) {
    return 1;
  }

  if (result.sanitisedName.includes(sanitisedSearch)) {
    return 2;
  }

  return 3;
};

export const searchUniversities = async (search: string) => {
  if (search.length === 0) {
    return [];
  }

  const searchData = await getUniversitiesForSearch();
  if (searchData.length === 0) {
    return [];
  }

  const sanitisedSearch = normalizeAccentText(search.toLowerCase());
  const keywords = extractKeywords(search);
  const filtered = searchData
    .filter((uni) =>
      keywords.every((keyword) =>
        uni.keywords.some((word) => word.startsWith(keyword)),
      ),
    )
    .map((result) => ({ result, rank: getRank(result, sanitisedSearch) }))
    .sort((a, b) => a.rank - b.rank)
    .map(({ result }) => result);

  return filtered.slice(0, maxResults).map<TypeaheadOption>(({ name }) => ({
    label: name,
    value: name,
  }));
};
