export const local = 'beamery.local';

export const aether = 'aether.staging.beamery.engineer';
export const devops = 'devops.staging.beamery.engineer';
export const platform = 'platform.beamery.engineer';
export const edge = 'edge.staging.beamery.engineer';
export const iceberg = 'iceberg.staging.beamery.engineer';
export const integrations = 'integrations.staging.beamery.engineer';
export const ouroboros = 'ouroboros.staging.beamery.engineer';
export const qa = 'qa.staging.beamery.engineer';
export const quantum = 'quantum.staging.beamery.engineer';
export const rocket = 'rocket.staging.beamery.engineer';
export const quartz = 'quartz.staging.beamery.engineer';
export const spark = 'spark.staging.beamery.engineer';
export const chicory = 'chicory.staging.beamery.engineer';
export const corefe = 'corefe.staging.beamery.engineer';

export const perf = 'perf.beamery.engineer';

export const canaryAmazon = 'amazon.canary.beamery.com';
export const canaryUs = 'canary.beamery.com';

export const previewUs = 'preview.beamery.com';

export const sandbox = 'sandbox.beamery.com';
export const sandboxEu = 'sandbox.beamery.eu';

export const productionAmazon = 'amazon.beamery.com';
export const productionUbs = 'ubs.beamery.eu';
export const productionUs = 'beamery.com';
export const productionEu = 'beamery.eu';
