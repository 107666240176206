import type { ReactNode } from 'react';
import { ReactI18nextProvider } from 'react-i18next-helpers';
import { i18NextConfig } from './i18n-config';

interface I18nProviderProps {
  children?: ReactNode;
}

export const I18nProvider = ({ children }: I18nProviderProps) => (
  <ReactI18nextProvider config={i18NextConfig}>{children}</ReactI18nextProvider>
);
