import { createContext, useContext, useMemo } from 'react';
import { useCurrentDomain } from 'candidate-facing-components';
import type { Metrics } from '../../api/metrics';
import type {
  MetricsContextValue,
  MetricsProviderProps,
} from './metrics.interface';

const defaultValue: MetricsContextValue = {
  metrics: {
    isCustomDomain: false,
    isEmbedded: false,
    embeddedType: undefined,
  },
};

export const MetricsContext = createContext(defaultValue);

export const MetricsProvider = ({
  children,
  isEmbedded = false,
  embeddedType,
}: MetricsProviderProps) => {
  const { isVanityDomain } = useCurrentDomain();

  const value = useMemo<MetricsContextValue>(
    () => ({
      metrics: {
        isCustomDomain: isVanityDomain,
        isEmbedded,
        embeddedType,
      },
    }),
    [isVanityDomain, isEmbedded, embeddedType],
  );

  return (
    <MetricsContext.Provider value={value}>{children}</MetricsContext.Provider>
  );
};

export const useMetrics = (): Metrics => {
  const { metrics } = useContext(MetricsContext);

  return metrics;
};
