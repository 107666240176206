import type { CookieConsent } from 'form-definition';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { noop } from 'utils';
import { useCurrentDomain } from 'candidate-facing-components';
import { createGlobalStyle } from 'styled-components';
import type {
  OneTrustContextValue,
  OneTrustProviderProps,
} from './onetrust.interface';
import { injectOneTrust } from './utils';

// This has to match the styling found in app-pages exactly, as the customer can set CSS in company settings
// that affects the button in both places
const GlobalOneTrustStyles = createGlobalStyle`
  #optanon-minimize-wrapper #ot-sdk-btn {
    font-family: Arial;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-text-size-adjust: none;
    font-size: 0.63em;
    color: #000;
    margin: auto;
    text-decoration: none;
    position: relative;
    background-color: #d7d7d7;
    background-repeat: no-repeat;
    border: solid;
    border-color: #333;
    cursor: pointer;
    overflow: hidden;
    border-width: 1px;
    padding: 10px;
    border-radius: 8px;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

const defaultCookieSettingsCssString = `
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1;`;

// Styles are set using the style attribute as it has to match the CSS that app-pages uses exactly, as the customer
// can set overriding CSS in company settings
const CookieSettingsButton = ({ settings }: { settings: CookieConsent }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (settings.settingsButtonCss !== null && buttonRef.current) {
      buttonRef.current.setAttribute('style', settings.settingsButtonCss);
    } else if (buttonRef.current) {
      buttonRef.current.setAttribute('style', defaultCookieSettingsCssString);
    }
  }, [buttonRef, settings.settingsButtonCss]);

  // Onetrust script will find this button by its ID and class and will set its content
  // based on the customer's Onetrust setup
  return (
    <div id='optanon-minimize-wrapper'>
      <GlobalOneTrustStyles />
      <button
        type='button'
        id='ot-sdk-btn'
        className='ot-sdk-show-settings'
        ref={buttonRef}
      >
        Cookie Settings
      </button>
    </div>
  );
};

const defaultValue: OneTrustContextValue = {
  addAcceptedListener: noop,
  setSettings: noop,
};

const OneTrustContext = createContext(defaultValue);

export const OneTrustProvider = ({ children }: OneTrustProviderProps) => {
  const [settings, setSettings] = useState<CookieConsent | null>(null);
  const listenersRef = useRef<Record<string, (() => void)[]>>({});
  const acceptedRef = useRef<string[]>([]);
  const { isVanityDomain } = useCurrentDomain();

  useEffect(() => {
    if (settings === null) {
      return;
    }
    const handleGroupAccepted = (group: string) => {
      acceptedRef.current.push(group);
      listenersRef.current[group]?.forEach((cb) => cb());
      listenersRef.current[group] = [];
    };

    if (!settings.enabled) {
      ['C0001', 'C0002', 'C0003', 'C0004', 'C0005'].forEach(
        handleGroupAccepted,
      );
      return;
    }

    injectOneTrust({
      settings,
      isEventsUrl:
        !isVanityDomain && window.location.hostname.startsWith('events'),
      callback: handleGroupAccepted,
      isVanityDomain,
    });
  }, [settings, isVanityDomain]);

  const addAcceptedListener = useCallback(
    (group: string, listener: () => void) => {
      if (acceptedRef.current.includes(group)) {
        listener();
        return undefined;
      }
      listenersRef.current[group] = listenersRef.current[group] ?? [];
      listenersRef.current[group].push(listener);
      return () => {
        listenersRef.current[group] = listenersRef.current[group]?.filter(
          (cb) => cb !== listener,
        );
      };
    },
    [],
  );

  const value = useMemo<OneTrustContextValue>(
    () => ({
      addAcceptedListener,
      setSettings,
    }),
    [addAcceptedListener],
  );

  const showCookieSettingsButton = settings && settings.enabled;

  return (
    <OneTrustContext.Provider value={value}>
      {children}
      {showCookieSettingsButton && <CookieSettingsButton settings={settings} />}
    </OneTrustContext.Provider>
  );
};

export const useOneTrustSettings = (settings: CookieConsent | null) => {
  const { setSettings } = useContext(OneTrustContext);

  useEffect(() => {
    setSettings(settings);
  }, [setSettings, settings]);
};

export const useOneTrustCallback = (group: string, callback: () => void) => {
  const { addAcceptedListener } = useContext(OneTrustContext);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(
    () => addAcceptedListener(group, () => callbackRef.current()),
    [addAcceptedListener, group],
  );
};
