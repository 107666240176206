import { Route, Routes } from 'react-router-dom';
import { PageNotFound } from './components/page-not-found';
import { CandidateFormPage } from './pages/candidate-form-page';
import {
  CandidateFormPagePath,
  CustomDomainCandidateFormPagePath,
} from './paths';

export const Router = () => (
  <Routes>
    <Route
      path={`${CustomDomainCandidateFormPagePath}/*`}
      element={<CandidateFormPage />}
    />
    <Route
      path={`${CandidateFormPagePath}/*`}
      element={<CandidateFormPage />}
    />
    <Route path='*' element={<PageNotFound />} />
  </Routes>
);
