import {
  Flex,
  Heading,
  Link,
  Paragraph,
  Stack,
} from '@beamery/lib-ds-components';
import type {
  Element,
  Text as TextElement,
} from '@beamery/component-rich-text';
import { DisplayRichText } from '@beamery/component-rich-text';
import styled from 'styled-components';
import { useMemo } from 'react';
import { useTranslation } from '../../../../hooks/use-translation';
import { dataEnrichmentUrl } from '../../../../config/beamery.config';
import type { ComplianceProps } from './compliance.interface';
import { DataProtectionField } from './data-protection-field';

const Container = styled(Flex)`
  gap: ${({ theme }) => theme.space.x1};
  position: relative;
`;
Container.defaultProps = {
  direction: 'column',
};

const LinkWithMargin = styled(Link)`
  margin-left: ${({ theme }) => theme.space.x3};
`;

export const ComplianceV2 = ({
  fieldName,
  compliance,
  fieldValidationRules,
  control,
  isLoggedIn = false,
  current,
}: ComplianceProps) => {
  const { t } = useTranslation();
  const introductionStatement = useMemo(
    () =>
      JSON.parse(compliance.consentRequestIntroductionStatement) as (
        | Element
        | TextElement
      )[],
    [compliance.consentRequestIntroductionStatement],
  );

  return (
    <Container>
      {compliance.title && <Heading variant='2'>{compliance.title}</Heading>}
      <Stack gapBetweenChildren='x4'>
        <div>
          <DisplayRichText content={introductionStatement} />

          <DataProtectionField
            current={current}
            isLoggedIn={isLoggedIn}
            control={control}
            fieldName={fieldName}
            fieldValidationRules={fieldValidationRules}
            label={compliance.consentQuestion}
          />
        </div>

        <Paragraph>
          <Link
            setting='inline'
            variant='emphasised'
            href={compliance.privacyPolicyUrl}
          >
            {compliance.privacyPolicyText || t('compliance.privacy-policy')}
          </Link>
          {compliance.showDataEnrichmentLink && (
            <LinkWithMargin
              setting='inline'
              variant='emphasised'
              href={dataEnrichmentUrl}
            >
              {compliance.dataEnrichmentLinkText ||
                t('compliance.data-enrichment')}
            </LinkWithMargin>
          )}
        </Paragraph>
      </Stack>
    </Container>
  );
};
