export type EmbeddedType = 'modal' | 'inline';

export interface Metrics {
  isCustomDomain: boolean;
  isEmbedded: boolean;
  embeddedType?: EmbeddedType;
}

export const metaHeaderName = 'x-convert-meta';

export const encodeMetrics = (metrics: Metrics) =>
  btoa(JSON.stringify(metrics));
