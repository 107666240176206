import type {
  DataProtectionElement,
  FormElement,
  FormInputElement,
  TextElement,
} from 'form-definition';

export const isInputElement = (
  element: FormElement | null,
): element is FormInputElement => !!element?.field;

export const isTextElement = (element: FormElement): element is TextElement =>
  element.type === 'text';

export const isDataProtectionElement = (
  element: FormElement,
): element is DataProtectionElement => element.type === 'dataProtection';
