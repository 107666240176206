import type { CallbackError, InitOptions, ResourceKey } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import type { ReactI18NextConfig } from 'react-i18next-helpers';

export const candidateExperienceI18nextConfig: Partial<InitOptions> = {
  ns: 'candidate-experience',
  defaultNS: 'candidate-experience',
};

export const i18NextConfig: ReactI18NextConfig = {
  modulesToUse: [
    resourcesToBackend((language, namespace, callback) => {
      import(`./locales/${language}/${namespace}.json`)
        .then((resources: ResourceKey) => {
          callback(null, resources);
        })
        .catch((error: CallbackError) => {
          callback(error, null);
        });
    }),
  ],
  options: candidateExperienceI18nextConfig,
};
