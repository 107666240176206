import { Link, Heading, Divider } from '@beamery/lib-ds-components';
import type { MarkupProps } from 'interweave';
import { Markup } from 'interweave';
import {
  OrderedList,
  UnorderedList,
  StyledParagraph,
} from './sanitized-html.styles';

interface SanitizedHtmlProps {
  children: string;
  className?: string;
}

export const SanitizedHtml = ({ children, className }: SanitizedHtmlProps) => {
  const transform: MarkupProps['transform'] = (element, content, config) => {
    switch (config.tagName) {
      case 'p':
        return <StyledParagraph>{content}</StyledParagraph>;
      case 'a':
        return (
          <Link href={element.getAttribute('href') as string} setting='inline'>
            {content}
          </Link>
        );
      case 'h1':
        return <Heading variant='1'>{content}</Heading>;
      case 'h2':
        return <Heading variant='2'>{content}</Heading>;
      case 'h3':
        return <Heading variant='3'>{content}</Heading>;
      case 'h4':
        return <Heading variant='4'>{content}</Heading>;
      case 'h5':
      case 'h6':
        return <Heading variant='5'>{content}</Heading>;
      case 'ul':
        return <UnorderedList>{content}</UnorderedList>;
      case 'ol':
        return <OrderedList>{content}</OrderedList>;
      case 'hr':
        return <Divider />;
      default:
        return undefined;
    }
  };

  return (
    <Markup
      className={className}
      content={children}
      allowList={[
        'strong',
        'em',
        'u',
        'p',
        'a',
        'br',
        'ul',
        'ol',
        'li',
        'hr',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
      ]}
      transformOnlyAllowList
      transform={transform}
    />
  );
};
