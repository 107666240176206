export const getCaptcha = (key: string | null) => {
  if (!window.grecaptcha || key === null) {
    return Promise.resolve(null);
  }

  return new Promise<string>((resolve, reject) => {
    grecaptcha.ready(async () => {
      try {
        const grecaptchaResponse = await grecaptcha.execute(key, {
          action: 'submit',
        });
        resolve(grecaptchaResponse);
      } catch (error) {
        reject(error);
      }
    });
  });
};
