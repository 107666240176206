import type { Submission } from 'candidate-facing-components';
import type { PublicFormDefinition } from 'form-definition';
import { getFrontierApiUrl } from '../utils/url';
import { encodeMetrics, metaHeaderName, type Metrics } from './metrics';
import { fetchGql } from './core';
import { getCaptcha, processUploads } from './form-submission';
import { getPublicFormBySlug, getPublicFormById } from './queries';
import type {
  GetFormBySlugVariables,
  GetFormByIdVariables,
  NavigationItems,
} from './types/form.queries';
import type { FormSubmissionInput } from './types/form.mutations';
import { formSubmit } from './mutations';
import { refreshAuthToken } from './candidate-auth';
import { apiConvertUpload } from './endpoints';

export const getFormBySlug = async ({
  formSlug,
  companySlug = '',
  candidateId = undefined,
  metrics,
  refreshToken = true,
}: {
  formSlug: string;
  companySlug?: string;
  candidateId?: string;
  metrics: Metrics;
  refreshToken?: boolean;
}): Promise<{
  formGetPublic: PublicFormDefinition;
  navigationItems: NavigationItems;
}> => {
  const { formGetPublicBySlug, navigationItems } = await fetchGql<
    {
      formGetPublicBySlug: PublicFormDefinition;
      navigationItems: NavigationItems;
    },
    GetFormBySlugVariables
  >({
    query: getPublicFormBySlug,
    variables: {
      companySlug,
      formSlug,
      candidateId,
    },
    headers: {
      [metaHeaderName]: encodeMetrics(metrics),
    },
  });

  if (formGetPublicBySlug.authenticated === false && refreshToken) {
    try {
      await refreshAuthToken(formSlug);
    } catch {
      return { formGetPublic: formGetPublicBySlug, navigationItems };
    }
    return getFormBySlug({
      formSlug,
      companySlug,
      metrics,
      refreshToken: false,
    });
  }

  return { formGetPublic: formGetPublicBySlug, navigationItems };
};

export const getFormById = async ({
  formId,
  companyId,
  candidateId = undefined,
  metrics,
}: {
  formId: string;
  companyId: string;
  candidateId?: string;
  metrics: Metrics;
}): Promise<{
  formGetPublic: PublicFormDefinition;
  navigationItems: NavigationItems;
}> => {
  const { formGetPublicById, navigationItems } = await fetchGql<
    {
      formGetPublicById: PublicFormDefinition;
      navigationItems: NavigationItems;
    },
    GetFormByIdVariables
  >({
    query: getPublicFormById,
    variables: {
      id: formId,
      companyId,
      candidateId,
    },
    headers: {
      [metaHeaderName]: encodeMetrics(metrics),
    },
  });
  return { formGetPublic: formGetPublicById, navigationItems };
};

interface FormIdentifiers {
  id: string;
  slug: string;
  companySlug: string;
  companyId: string;
}

interface SubmissionConfig extends FormIdentifiers {
  recaptchaKey: string | null;
  candidateId: string | null;
}

interface SubmissionOptions {
  config: SubmissionConfig;
  submission: Submission;
  metrics: Metrics;
  isAuthenticated?: boolean;
  urlParameterString: string;
}

export const submitForm = async ({
  config,
  submission,
  metrics,
  isAuthenticated,
  urlParameterString,
}: SubmissionOptions) => {
  const { id, slug, companyId, recaptchaKey, candidateId } = config;
  const attachmentApiUrl = getFrontierApiUrl(
    `${apiConvertUpload}/${companyId}/${id}`,
  );

  const [compiledSubmission, captcha] = await Promise.all([
    processUploads(attachmentApiUrl)(submission),
    getCaptcha(recaptchaKey),
    isAuthenticated ? refreshAuthToken(slug) : null,
  ]);

  await fetchGql<{ formSubmit: string }, { input: FormSubmissionInput }>({
    mutation: formSubmit,
    variables: {
      input: {
        formId: id,
        companyId,
        candidateId,
        answers: Object.entries(compiledSubmission).map(
          ([elementId, userInput]) => ({
            elementId,
            userInput,
          }),
        ),
        captcha,
        urlParameterString,
      },
    },
    headers: {
      [metaHeaderName]: encodeMetrics(metrics),
    },
  });
};
