import { Flex, Pill } from '@beamery/lib-ds-components';
import styled from 'styled-components';

export const Container = styled(Flex)`
  gap: ${({ theme }) => theme.space.x1};
`;
Container.defaultProps = {
  direction: 'column',
};

export const StyledPill = styled(Pill)`
  display: flex;
  margin-right: ${({ theme }) => theme.space.x1};
  align-items: center;
  padding-right: 0;
  cursor: default;
`;

export const ClearButton = styled.button`
  all: unset;
  margin-right: ${({ theme }) => theme.space.x2};
  cursor: pointer;
  height: ${({ theme }) => theme.sizes.x4};
  color: ${({ theme }) => theme.colors.text.default};
`;

export const RemoveButton = styled(ClearButton)`
  margin-right: 0;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${({ theme }) => theme.colors.controls.secondaryHover};
  }

  &:active {
    background: ${({ theme }) => theme.colors.controls.secondaryActive};
  }
`;

export const LoadingContainer = styled.div`
  height: 50px;
`;
