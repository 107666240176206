import * as hosts from './beamery.hosts';
import { parentDomain } from './hosts';

export const getEnvironmentName = (domain = parentDomain) => {
  if (process.env.NODE_ENV === 'development') {
    return 'DEVELOPMENT';
  }

  switch (domain) {
    case hosts.canaryAmazon:
      return 'Amazon Canary';
    case hosts.canaryUs:
      return 'Canary';
    case hosts.previewUs:
      return 'Preview US';
    case hosts.productionAmazon:
      return 'Production Amazon';
    case hosts.productionUbs:
      return 'Production UBS';
    case hosts.productionUs:
      return 'Production US';
    case hosts.productionEu:
      return 'Production EU';
    case hosts.sandbox:
      return 'Sandbox';
    case hosts.sandboxEu:
      return 'Sandbox EU';
    default:
      return 'Staging';
  }
};
