import { gql } from 'graphql-tag';

const FormDefinitionFragment = gql`
  fragment Form on FormDefinition {
    id
    authenticated
    modified
    settings {
      slug
      externalName
      useCustomBranding
      customBrandName
      customBrandLogoUrl
      authenticated
    }
    companySettings {
      accentColor
      companyLogoUrl
      companyName
      companyId
      companySlug
      cookieSettings
      compliances {
        title
        consentRequestIntroductionStatement
        consentQuestion
        privacyPolicyUrl
        showDataEnrichmentLink
        privacyPolicyText
        dataEnrichmentLinkText
        version
      }
      gaKey
      gtmKey
      vanityUrls
      customFontUrl
      customFontFamilyName
      recaptchaEnabled
      socialMedia {
        facebook
        twitter
        instagram
        youtube
        linkedin
        glassdoor
        wechat
      }
    }
    flexibleOptions {
      singleSelectPlaceholder
      singleSelectFilterText
      multiSelectPlaceholder
      multiSelectFilterText
      multiSelectSelectedText
      requiredFieldErrorMessage
      typeaheadNoResultsMessage
      characterLimitMessage
      submitToastMessage
      backButtonText
      nextButtonText
      restartButtonText
      submitButtonText
    }
    pages {
      id
      pageType
      title
      onSubmit
      elements {
        id
        required
        field
        type
        meta
        value
      }
    }
  }
`;

const NavigationItemsFragment = gql`
  fragment Navigation on NavigationItem {
    id
    title
    url
    icon
  }
`;

export const getPublicFormBySlug = gql`
  ${FormDefinitionFragment}
  ${NavigationItemsFragment}
  query formGetPublicBySlug(
    $companySlug: String
    $formSlug: String!
    $candidateId: String
  ) {
    formGetPublicBySlug(
      companySlug: $companySlug
      formSlug: $formSlug
      candidateId: $candidateId
    ) {
      ...Form
    }
    navigationItems {
      ...Navigation
    }
  }
`;

export const getPublicFormById = gql`
  ${FormDefinitionFragment}
  ${NavigationItemsFragment}
  query formGetPublicById($id: ID!, $companyId: ID!, $candidateId: String) {
    formGetPublicById(
      id: $id
      companyId: $companyId
      candidateId: $candidateId
    ) {
      ...Form
    }
    navigationItems {
      ...Navigation
    }
  }
`;
