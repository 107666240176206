import type { BackendModule, InitOptions } from 'i18next';
import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Pseudo from 'i18next-pseudo';
import { launchDarklyFeatureFlag, type Logger } from 'utils';

export interface ReactI18NextConfig {
  modulesToUse: Array<BackendModule<object>>;
  options: Partial<InitOptions>;
}

const isBeamerySpanishLanguageFeatureFlagEnabled = launchDarklyFeatureFlag(
  'i18n_initial_language_es',
);

const isLocalStorageFeatureFlagEnabled =
  localStorage.getItem('convert-translations-enabled') === 'true';

const translationsEnabled =
  process.env.APP_NAME !== 'candidate-experience' &&
  (isBeamerySpanishLanguageFeatureFlagEnabled ||
    isLocalStorageFeatureFlagEnabled);

export const initialiseReactI18next = (
  { modulesToUse, options }: ReactI18NextConfig,
  logger: Logger,
) => {
  const instance = i18next.createInstance();

  instance
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .use(
      new Pseudo({
        languageToPseudo: 'test',
      }),
    );

  modulesToUse.forEach((module) => {
    instance.use(module);
  });

  void instance.init({
    detection: {
      caches: [],
    },
    fallbackLng: 'en',
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // React already protects from XSS, so escaped interpolation can be turned off in I18next: see https://react.i18next.com/getting-started
    },
    postProcess: ['pseudo'],
    saveMissing: true,
    missingKeyHandler: (languages, namespace, key) => {
      logger.info(`Translation missing for key ${key}`, {
        languages,
        namespace,
        key,
      });
    },
    ...(translationsEnabled
      ? {}
      : {
          supportedLngs: ['en'],
          nonExplicitSupportedLngs: true,
        }),
    ...options,
  });

  return instance;
};
