import type { Submission } from 'candidate-facing-components';

interface FileUploadResponse {
  id: string;
  url: string;
}

export const processUploads =
  (formApiUrl: string) => async (submission: Submission) => {
    const uploads = Object.entries(submission)
      .filter((entry): entry is [string, File] => entry[1] instanceof File)
      .map(async ([key, value]) => {
        const body = new FormData();
        body.append('file', value);
        const fileUploadResponse = await fetch(`${formApiUrl}`, {
          method: 'POST',
          body,
        });

        if (!fileUploadResponse.ok) {
          throw new Error(fileUploadResponse.statusText);
        }
        const fileUploadInfo =
          (await fileUploadResponse.json()) as FileUploadResponse;
        return { key, value: fileUploadInfo.id };
      });

    const uploadResults = await Promise.all(uploads);
    return uploadResults.reduce(
      (sub, { key, value }) => ({ ...sub, [key]: value }),
      submission,
    );
  };
